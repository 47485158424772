import { Id } from '@app/shared/services/assets.service';

export enum AssetType {
    PRODUCT = 'PRODUCT',
    SOLUTION = 'SOLUTION',
}

export enum AssetStatus {
    DRAFT = 'DRAFT',
    AWAITING_FOR_APPROVAL = 'AWAITING_FOR_APPROVAL',
    AWAITING_LAUNCH = 'AWAITING_LAUNCH',
    LAUNCHED = 'LAUNCHED',
    REJECTED = 'REJECTED',
}

export enum AssetEvent {
    SAVE_DRAFT = 'SAVE_DRAFT',
    SUBMIT = 'SUBMIT',
    ADMIN_APPROVE = 'ADMIN_APPROVE',
    ADMIN_REJECT = 'ADMIN_REJECT',
    CANCEL = 'CANCEL',
    LAUNCH = 'LAUNCH',
    UPGRADE_VERSION = 'UPGRADE_VERSION',
}

export interface AssetDto {
    id: number;
    assetType: AssetType;
    assetName: string;
    tags: string[];
    assetVersion: string;
    publishDate: string;
    description: string;
    logoExists: boolean;
    repoUrl: string;
    downloadable: AssetFileDto[];
    averageScore: number;
    reviewCount: number;
    downloadCount: number;
    recommended: boolean;
    status: AssetStatus | null;
    assetVersionId: number;
    lastModifiedAt: string;
    createdBy: number;
    categoryId: Id;
    overview: string;
    highlights: string | null;
    reviewedByUser: boolean;
    reviewCountByMark: Record<number, number>;
    systemMetadataFieldValues: { fieldName: string; value: string }[];
    customMetadataFieldValues: { fieldName: string; value: string }[];
    groupId: number;
    groupName: string;
}

export interface AssetCreationDto {
    id: number;
    assetType: AssetType;
    assetName: string;
    groupId: number;
    versionDetails: AssetVersionDto;
    supportDetails: AssetSupportDto;
    classificationDetails: AssetClassificationDto;
    customMetadataValues: AssetMetaDataFieldValueDto[];
    systemMetadataValues: AssetMetaDataFieldValueDto[];
    documentDetails: AssetDocumentDto;
}

export interface AssetVersionDto {
    id: number;
    assetVersion: string;
    previousVersions: string[];
    repoUrl: string;
    description: string;
    highlights: string;
    overview: string;
}

export interface AssetVersionSlimDto {
    versionId: number;
    version: string;
    createdAt: string;
    tags: string[];
    description: string;
    overview: string;
    lastModifiedAt: string;
}

export interface AssetSupportDto {
    url: string;
    email: string;
    phone: string;
    content: string;
    files?: AssetFileDto[];
}

export interface AssetUsageDto {
    downloadable: AssetFileDto[];
    demoVideos: AssetFileDto[];
    files: AssetFileDto[];
}

export interface AssetClassificationDto {
    primaryCategoryId: number;
    secondaryCategoryId: number;
    searchKeywordIds: number[];
    relatedVersionIds: number[];
    metadataId: number;
}

export interface AssetMetaDataFieldValueDto {
    fieldId: number;
    value: string;
    dropdownId: number;
}

export interface AssetDocumentDto {
    downloadable: AssetFileDto[];
    demoVideos: AssetFileDto[];
    logo: AssetFileDto;
    supportDocuments: AssetFileDto[];
    usageDocuments: AssetFileDto[];
}

export interface AssetFileDto {
    uuid: string;
    name: string;
    originalFileName: string;
    size: number;
}

export interface AssetActionResponse {
    assetId: number;
    versionId: number;
    newStatus: AssetStatus;
}

export enum FileType {
    PRODUCT_LOGO = 'PRODUCT_LOGO',
    DEMO_VIDEO = 'DEMO_VIDEO',
    PRODUCT_DOWNLOADABLE = 'PRODUCT_DOWNLOADABLE',
    PRODUCT_SUPPORT = 'PRODUCT_SUPPORT',
    PRODUCT_USAGE = 'PRODUCT_USAGE',
}
