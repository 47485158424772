export type ToolCategoryType =
    | 'CISRV'
    | 'SCM'
    | 'CSCAN'
    | 'PM'
    | 'OTHER'
    | 'ARTIFACTORY'
    | 'NOTIFICATION'
    | 'MONITORING'
    | 'ITSM'
    | 'TEST_MANAGEMENT';


export type ToolCategory =
    | 'CI/CD Servers'
    | 'Source Code Management'
    | 'Code Scan'
    | 'Project Management'
    | 'Others'
    | 'Artifactory'
    | 'Monitoring'
    | 'Notification'
    | 'ITSM'
    | 'Test Management';

export type ToolType = 'CISRV_BAMBOO' | 'SCM_BB' | 'CSCAN_SONAR' | 'SCM_GL' | 'SCM_GH' | 'PM_JIRA' | 'CISRV_JENKINS' | 'INTEGRATION_XPRESS';

export type ToolAuthenticationType = 'FORM' | 'TOKEN' | 'BASIC' | 'SCRIPT' | 'JDBC';

export interface ToolTemplate {
    id: number;
    name: string;
    sequence: number;
    description: string;
    imageUrl: string;
    pluginType: string;
    scope: string;
    pluginCategories: ToolCategoryType[];
    pluginCategory: ToolCategory;
    defaultSchedulerTime: number;
    componentCollection: string;
    subCollection: string;
    verificationPath: string;
    groupCategory: ToolCategoryType;
}

export interface ToolModel {
    id: number;
    toolId: string;
    name: string;
    description: string;
    enabled: boolean;
    sequence: number;
    defaultSchedulerTime: number;
    componentCollection: string;
    subCollection: string;
    baseUrl: string;
    imageUrl: string;
    verificationPath: string;
    authHeaderTemplate: string;

    pluginType: ToolType;
    pluginCategories: ToolCategoryType[];
    authType: ToolAuthenticationType;

    jobSetId: number;
}

export interface ProjectItem {
    id: number;
    name: string;
    code?: string;
    category?: ToolCategoryType;
    type?: ToolType;
    fake?: boolean;
    toolId?: string;
    items?: ProjectItem[];
}

export const TOOL_CATEGORY_NAMES = new Map<ToolCategoryType, ToolCategory>([
    ['PM', 'Project Management'],
    ['SCM', 'Source Code Management'],
    ['CISRV', 'CI/CD Servers'],
    ['CSCAN', 'Code Scan'],
    ['TEST_MANAGEMENT', 'Test Management'],
    ['ARTIFACTORY', 'Artifactory'],
    ['MONITORING', 'Monitoring'],
    ['NOTIFICATION', 'Notification'],
    ['ITSM', 'ITSM'],
    ['OTHER', 'Others']
]);

export interface ToolGroup {
    category: string;
    groupCategory: ToolCategoryType;
    sequence: string;
    plugins: ToolModel[];
}

export interface ToolCreateResponse {
    id: number;
    toolId: string;
    pluginCategories: ToolCategoryType[];
}

export function sortObjectArrayLikeMapValuesOrder(
    array: any[],
    key: string,
    map: Map<any, any>
) {
    const mapValues = Array.from(map.values());
    array.sort((a, b) => {
        const aKey = a[key];
        const bKey = b[key];
        const aOrder = mapValues.findIndex(x => x === aKey);
        const bOrder = mapValues.findIndex(x => x === bKey);
        return aOrder < bOrder ? -1 : 1;
    });

    return array;
}
