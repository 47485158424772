<ng-container *transloco="let t; read: 'assets.card'">
    <div class="d-flex" [class.text-truncate]="isInList" [class.flex-column]="isGridView">
        <div class="d-flex" [class.text-truncate]="isInList">
            <div class="asset-card--sidebar flex-shrink-0">
                <div class="asset-pic">
                    <svg *ngIf="!asset.logoExists" width="80" height="80" viewBox="0 0 80 80" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M63.2138 80H16.7761C15.5102 79.9974 14.297 79.5031 13.4018 78.6255C12.5067 77.7478 12.0027 76.5582 12 75.317V4.69295C12 3.45002 12.5029 2.25785 13.3983 1.37804C14.2937 0.498222 15.5085 0.00262776 16.7761 0H63.2138C63.8423 0 64.4647 0.121384 65.0454 0.357226C65.6261 0.593069 66.1537 0.938744 66.5982 1.37453C67.0426 1.81031 67.3951 2.32766 67.6357 2.89704C67.8762 3.46641 68 4.07666 68 4.69295V75.317C67.9973 76.5599 67.4919 77.751 66.5946 78.629C65.6973 79.5069 64.4814 80 63.2138 80ZM16.7761 1.3295C15.8574 1.33213 14.9772 1.69183 14.3284 2.32975C13.6797 2.96768 13.3155 3.83179 13.3155 4.73264V75.3567C13.3155 76.2566 13.6801 77.1197 14.3291 77.756C14.9781 78.3924 15.8583 78.7499 16.7761 78.7499H63.2138C64.1325 78.7499 65.0138 78.3927 65.6644 77.7566C66.315 77.1206 66.6819 76.2575 66.6846 75.3567V4.73264C66.6819 3.83088 66.3154 2.96679 65.665 2.32915C65.0147 1.6915 64.1335 1.33212 63.2138 1.3295H16.7761Z"
                            fill="#4CB6C4"
                        />
                        <path
                            d="M44.3076 38.9399C42.7704 38.9399 41.2678 38.484 39.9898 37.6297C38.7118 36.7755 37.7159 35.5614 37.1281 34.141C36.5403 32.7207 36.387 31.1579 36.6876 29.6504C36.9883 28.1429 37.7293 26.7584 38.8169 25.6721C39.9046 24.5859 41.29 23.8467 42.7979 23.548C44.3058 23.2493 45.8684 23.4046 47.288 23.9942C48.7076 24.5839 49.9204 25.5813 50.773 26.8604C51.6256 28.1395 52.0796 29.6427 52.0776 31.1799C52.0749 33.2389 51.2552 35.2127 49.7983 36.6677C48.3414 38.1227 46.3666 38.9399 44.3076 38.9399ZM44.3076 24.7099C43.028 24.7099 41.777 25.0894 40.7131 25.8003C39.6491 26.5112 38.8198 27.5217 38.3301 28.7039C37.8404 29.8862 37.7123 31.1871 37.9619 32.4421C38.2116 33.6972 38.8278 34.85 39.7326 35.7549C40.6375 36.6597 41.7903 37.2759 43.0454 37.5256C44.3004 37.7752 45.6013 37.6471 46.7836 37.1574C47.9658 36.6677 48.9763 35.8384 49.6872 34.7744C50.3981 33.7105 50.7776 32.4595 50.7776 31.1799C50.7789 30.3299 50.6125 29.488 50.2878 28.7024C49.9631 27.9168 49.4866 27.203 48.8855 26.602C48.2845 26.0009 47.5707 25.5244 46.7851 25.1997C45.9995 24.875 45.1576 24.7086 44.3076 24.7099Z"
                            fill="#4CB6C4"
                        />
                        <path
                            d="M12 47.368L20.5776 39.8901C21.584 39.0514 22.8526 38.5921 24.1626 38.5921C25.4726 38.5921 26.7412 39.0514 27.7476 39.8901L56.9876 68.2701L57.5176 67.7901L57.9476 67.3101L28.6976 38.9101C27.443 37.8388 25.8473 37.2502 24.1976 37.2502C22.5478 37.2502 20.9522 37.8388 19.6976 38.9101L12 45.6311V47.368Z"
                            fill="#4CB6C4"
                        />
                        <path
                            d="M67.9998 45.9435L61.8874 41.83C60.7529 41.1193 59.4412 40.7424 58.1024 40.7424C56.7637 40.7424 55.452 41.1193 54.3174 41.83L40.6774 51C40.6013 51.0449 40.5351 51.1048 40.4829 51.1762C40.4307 51.2475 40.3936 51.3288 40.3738 51.4149C40.3541 51.5011 40.3521 51.5904 40.3681 51.6773C40.384 51.7643 40.4176 51.847 40.4666 51.9206C40.5156 51.9941 40.5792 52.0569 40.6533 52.1051C40.7274 52.1532 40.8106 52.1858 40.8977 52.2007C40.9848 52.2156 41.0741 52.2126 41.16 52.1919C41.2459 52.1711 41.3267 52.1331 41.3974 52.08L55.0474 42.91C55.9669 42.3417 57.0265 42.0407 58.1074 42.0407C59.1884 42.0407 60.248 42.3417 61.1674 42.91L67.9998 47.5068V45.9435Z"
                            fill="#4CB6C4"
                        />
                    </svg>
                    <img *ngIf="asset.logoExists" [src]="asset | assetLogoUrl" width="80" [alt]="asset.assetName"/>
                </div>
                <ng-template [ngTemplateOutlet]="!isGridView ? rating : null"></ng-template>
            </div>
            <div class="asset-description-block" [class.text-truncate]="isInList">
                <ng-template [ngTemplateOutlet]="topInfo"></ng-template>
                <ng-template [ngTemplateOutlet]="!isGridView ? details : rating"></ng-template>
            </div>
        </div>
        <ng-template [ngTemplateOutlet]="isGridView ? details : null"></ng-template>
    </div>
</ng-container>

<ng-template #rating>
    <div class="asset-rating">
        <lib-rating *ngIf="asset.averageScore"
                    [value]="asset.averageScore"
                    [expanded]="true"
                    [ratingStatistics]="ratingStatistics$"
                    [translatedText]="translatedRatingText"
        ></lib-rating>
        <button *ngIf="canLeaveReview" class="btn btn-primary w-100 mt-3 mb-2" (click)="writeReview()">
            {{ 'assets.card.rateItNow' | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #topInfo>
    <div class="asset-top-info line-height-18px" [class.text-truncate]="isInList">
        <div class="disable font-12px line-height-16px">№ {{ asset.id }}</div>
        <div class="d-flex align-items-center my-4px" [class.text-truncate]="isInList">
            <fa-icon
                *ngIf="asset.recommended"
                class="font-18px mr-16px color-brand"
                icon="check-broken"
                [ngbTooltip]="'assets.card.recommendedTooltip' | transloco"
                container="body"
                [openDelay]="500"
            ></fa-icon>
            <a [routerLink]="(isManageAsset ? '/manage-assets/' : '/assets/') + asset.id + '/version/' + asset.assetVersionId"
               [ngClass]="isInList ? 'font-14px' : 'h1'"
               [class.text-truncate]="isInList"
               class="my-0"
               [ngbTooltip]="asset.assetName"
               container="body"
               triggers="manual"
               libTooltipWhenOverflow
            >
                {{ asset.assetName }}
            </a>
        </div>
        <app-asset-status-label class="my-4px max-width-fit-content"
                                *ngIf="showStatus && asset.status" [status]="asset.status"
        ></app-asset-status-label>
        <app-tags [tags]="asset.tags"></app-tags>
    </div>
</ng-template>

<ng-template #details>
    <div class="asset-details line-height-18px" [class.text-truncate]="isInList">
        <div class="d-flex align-items-center" [ngClass]="isGridView ? 'mt-16px' : 'mt-8px'">
            <div class="disable min-width-80px">{{ 'assets.card.version' | transloco }}:</div>
            <div class="ml-1">{{ asset.assetVersion }}</div>
            <div class="disable">
                <em class="publish-dot mx-2"></em>
                <app-date-badge [date]="asset.publishDate"></app-date-badge>
            </div>
        </div>
        <div class="d-flex mt-2px" [class.text-truncate]="isInList">
            <div class="disable min-width-80px">{{ 'assets.card.publisher' | transloco }}:</div>
            <div class="ml-1 flex-1"
                 [class.text-truncate]="isInList"
                 [ngbTooltip]="asset.groupName"
                 container="body"
                 triggers="manual"
                 libTooltipWhenOverflow
            >
                {{ asset.groupName }}
            </div>
        </div>
        <div class="d-flex mt-2px" [class.text-truncate]="isInList">
            <div class="disable min-width-80px">{{ 'assets.card.description' | transloco }}:</div>
            <div class="ml-1 flex-1"
                 [class.text-truncate]="isInList"
                 [ngbTooltip]="asset.description"
                 container="body"
                 triggers="manual"
                 libTooltipWhenOverflow
            >
                {{ asset.description }}
            </div>
        </div>
        <div class="d-flex mt-2px" *ngIf="!isInList">
            <div class="disable min-width-80px">{{ 'assets.card.overview' | transloco }}:</div>
            <div class="ml-1 flex-1"
                 [ngbTooltip]="asset.overview"
                 container="body"
                 triggers="manual"
                 libTooltipWhenOverflow
            >
                {{ asset.overview }}
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</ng-template>
