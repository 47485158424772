import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'redirectLink',
})
export class RedirectLinkPipe implements PipeTransform {
    transform(link: string) {
        return link.includes('http') ? link : `https://${link}`;
    }
}
