import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { concatMap, filter, pluck, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(private translocoService: TranslocoService) {}

    private payload$ = this.translocoService.events$.pipe(
        filter(e => {
            return e.type === 'translationLoadSuccess' || e.type === 'langChanged';
        }),
        pluck('payload'),
        shareReplay()
    );

    translateObject(key: string): Observable<any> {
        return this.payload$.pipe(
            concatMap(() => {
                return this.translocoService.selectTranslateObject(key);
            })
        );
    }
}
