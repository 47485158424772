import { Component, OnInit } from '@angular/core';
import { FileService } from '@app/shared/services/file.service';
import { combineLatest } from 'rxjs';

// const UUID_INDEX = 0;
// const FILE_INDEX = 1;

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
    progress: number = 0;
    isSpinnerVisible: boolean = false;

    constructor(private fileService: FileService) {}

    ngOnInit(): void {
        combineLatest([this.fileService.downloadingFiles$, this.fileService.isFileUploading$]).subscribe(
            ([downloadingFiles, isFileUploading]) => {
                this.isSpinnerVisible = isFileUploading || downloadingFiles.size > 0;
                /*this.isSpinnerVisible =
                    isFileUploading ||
                    [...downloadingFiles.keys()].filter(fileUuid => fileUuid.startsWith(DOWNLOAD_SELECTED_PREFIX))?.length > 0;

                // checking progress of the 1st File in the map
                this.progress =
                    [...downloadingFiles.entries()]?.filter(
                        downloadingFile => !downloadingFile[UUID_INDEX].startsWith(DOWNLOAD_SELECTED_PREFIX)
                    )?.[0]?.[FILE_INDEX]?.progress || 0;*/
            }
        );
    }
}
