<form [formGroup]="form" (reset)="activeModal.dismiss('Cancel')" (submit)="addReview()">
    <div *transloco="let transloco; read: 'reviewModal'" class="wrapper">
        <div class="modal-header">
            <h1 class="mt-0">{{ transloco('title') }}</h1>
            <button type="reset" class="close" aria-label="Close button" aria-describedby="modal-title">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="d-flex flex-column mb-3">
                <div class="d-flex align-items-center">
                    <span class="body2 mr-3">{{ transloco('rateItLabel') }} <span class="mandatory">*</span> </span>
                    <fa-icon
                        *ngFor="let star of stars; let i = index"
                        icon="star"
                        class="mr-2 star cursor-pointer"
                        [class.selected-star]="star <= ratingValue"
                        [class.empty-star]="star > ratingValue"
                        (click)="ratingValue = i + 1; showValidationMessage = false;"
                    ></fa-icon>
                </div>
                <div *ngIf="showValidationMessage" class="error-message-container color-red font-italic">
                    {{ transloco('ratingIsRequired') }}
                </div>
            </div>

            <lib-textarea
                class="mb-4 w-100"
                labelPosition="top"
                formControlName="advantages"
                [label]="transloco('advantagesLabel')"
                [placeholder]="transloco('advantagesPlaceholder')"
                [translatedText]="textareaTranslatedText"
                [resize]="false"
                [customHeight]="true"
            >
            </lib-textarea>

            <lib-textarea
                class="mb-4 w-100"
                labelPosition="top"
                formControlName="disadvantages"
                [label]="transloco('disadvantagesLabel')"
                [placeholder]="transloco('disadvantagesPlaceholder')"
                [translatedText]="textareaTranslatedText"
                [resize]="false"
                [customHeight]="true"
            >
            </lib-textarea>

            <lib-textarea
                class="mb-4 w-100"
                labelPosition="top"
                formControlName="comment"
                [label]="transloco('commentLabel')"
                [placeholder]="transloco('commentPlaceholder')"
                [translatedText]="textareaTranslatedText"
                [resize]="false"
                [customHeight]="true"
            >
            </lib-textarea>
        </div>

        <div class="modal-footer">
            <button type="reset" class="btn btn-secondary mr-25px ml-auto">
                {{ transloco('cancelBtn') }}
            </button>
            <button type="submit" class="btn btn-primary">
                {{ transloco('okBtn') }}
            </button>
        </div>
    </div>
</form>
