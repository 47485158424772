<div
    *ngIf="hasResetButton"
    data-export-id="reset-filter-button-container"
    class="clearfix my-2 d-flex align-items-center justify-content-end"
>
    <button type="button" class="btn btn-primary ml-20px extend-horizontal-padding" (click)="clearFilters()">
        Reset Column Filters
    </button>
</div>
<div #gridWrapper class="clearfix w-100" [style.height]="calcGridHeight" [style.maxHeight]="maxHeight">
    <ag-grid-angular
        [gridOptions]="gridOptions"
        [style.height]="'100%'"
        [style.maxHeight]="maxHeight"
        class="w-100 table-grid ag-theme-alpine widget-aggrid"
        [modules]="modules"
        [components]="components"
        [rowBuffer]="rowBuffer"
        [rowSelection]="tableData.rowSelection"
        [suppressRowDeselection]="tableData.suppressRowDeselection"
        [suppressCellSelection]="tableData.suppressCellSelection"
        [rowModelType]="'clientSide'"
        [columnDefs]="columnDefs"
        [tooltipShowDelay]="0"
        [infiniteInitialRowCount]="infiniteInitialRowCount"
        [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
        [pagination]="pagination"
        [paginationPageSize]="paginationPageSize"
        [suppressPaginationPanel]="suppressPaginationPanel"
        [rowClassRules]="rowClassRules"
        (gridReady)="onGridReady($event)"
        (columnResized)="onColumnResize()"
        (gridSizeChanged)="onGridReady($event)"
        (firstDataRendered)="onGridSizeChanged($event)"
        (gridColumnsChanged)="onGridSizeChanged($event)"
        (rowClicked)="rowClicked.emit($event)"
        (cellClicked)="cellClicked.emit($event)"
        (paginationChanged)="paginationChanged.emit($event)"
        (filterChanged)="filterChanged()"
        [rowData]="rowData"
        [pinnedTopRowData]="pinnedTopRowData"
        [pinnedBottomRowData]="pinnedBottomRowData"
        [rowHeight]="55"
        headerHeight="32"
    >
    </ag-grid-angular>
    <div *ngIf="showIssueCount && gridApi" class="mt-2">
        <span>Issue Count(s) : {{ gridApi.getModel().getRowCount() }}</span>
    </div>
</div>
