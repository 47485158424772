import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AssetFilter } from '../../../../services/assets.service';
import { TranslationService } from '@app/core/services/translation.service';

export type LayoutType = 'list' | 'grid';

export const SORTING_FIELDS_IN_ASSETS = ['name', 'publishDate', 'rating'];
export const SORTING_FIELDS_IN_MANAGE_ASSETS = ['name', 'publishDate', 'rating'];
export const SORTING_FIELDS_DEPENDENT_BY_VERSION = ['publishDate', 'rating'];

@Component({
    selector: 'app-assets-filter',
    templateUrl: './assets-filter.component.html',
    styleUrls: ['./assets-filter.component.scss'],
})
export class AssetsFilterComponent implements OnInit, OnChanges {
    @Input() layout: LayoutType = 'list';

    @Output() layoutChange = new EventEmitter<LayoutType>();

    @Input() filter: AssetFilter = null;

    @Output() filterChange = new EventEmitter<AssetFilter>();

    @Input() isManageAssets = true;

    sortingItems: { label: string; value: string }[] = [];

    sortingButtonText: string;

    constructor(private translationService: TranslationService) {}

    ngOnInit(): void {
        this.translationService.translateObject('assets.filter').subscribe(filter => {
            this.sortingItems = [];
            (this.isManageAssets ? SORTING_FIELDS_IN_MANAGE_ASSETS : SORTING_FIELDS_IN_ASSETS).forEach(field => {
                this.sortingItems.push({ label: filter[field + 'ASC'], value: field + ',asc' });
                this.sortingItems.push({ label: filter[field + 'DESC'], value: field + ',desc' });
            });
            this.sortingButtonText = this.sortingItems.find(i => i.value === this.filter.sort).label;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.filter && changes.filter.previousValue !== changes.filter.currentValue) {
            this.sortingButtonText = this.sortingItems.find(i => i.value === this.filter.sort)?.label;
        }
    }

    changeSorting({ value }: { value: string }) {
        this.filterChange.emit({
            ...this.filter,
            sort: value,
        });
    }

    search(searchTerm: string) {
        this.filterChange.emit({
            ...this.filter,
            searchTerm,
        });
    }
}
