import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { AvatarUrlPipe } from './pipes/avatar-url.pipe';
import { SharedComponentsModule } from '@dagility-ui/shared-components';
import { CheckboxGroupComponent } from '@app/shared/components/checkbox-group/checkbox-group.component';
import { IsAuthorizedDirective } from '@app/shared/directives/is-authorized.directive';
import { UserEditFormComponent } from './components/user-edit-form/user-edit-form.component';
import { AssetsListWrapperComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-list-wrapper.component';
import { AssetsFilterComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-filter/assets-filter.component';
import { AssetsListComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-list/assets-list.component';
import { AssetListItemComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-list/asset-list-item/asset-list-item.component';
import { AssetCardComponent } from './components/assets/asset-card/asset-card.component';
import { AssetStatusLabelComponent } from '@app/shared/components/assets/asset-status-label/asset-status-label.component';
import { TranslocoRootModule } from '../transloco-root.module';
import { RejectReasonModalComponent } from './components/assets/assets-list-wrapper/assets-list/asset-list-item/reject-reason-modal/reject-reason-modal.component';
import { UpgradeVersionModalComponent } from './components/upgrade-version-modal/upgrade-version-modal.component';
import { RedirectLinkPipe } from '@app/shared/pipes/redirect-link.pipe';
import { AssetLogoUrlPipe } from '@app/shared/pipes/asset-logo-url.pipe';
import { DateBadgeComponent } from './components/date-badge/date-badge.component';
import { ReviewModalService } from '@app/shared/services/review-modal.service';
import { AssetVersionCardComponent } from './components/assets/asset-version-card/asset-version-card.component';
import { TagsComponent } from './components/tags/tags.component';
import { NoDataAvailableComponent } from './components/no-data-available/no-data-available.component';
import { PageIsUnderDevelopmentComponent } from './components/page-is-under-development/page-is-under-development.component';
import { AssetPriceTagComponent } from './components/asset-price-tag/asset-price-tag.component';
import { DownloadFilesModalComponent } from './components/download-files-modal/download-files-modal.component';
import { UsersTableComponent } from './components/users-table/users-table.component';

const modules = [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    NgbModule,
    NgSelectModule,
    ColorPickerModule,
    RouterModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    DragDropModule,
    SharedComponentsModule,
    TranslocoRootModule,
];
const components: Type<any>[] = [
    CheckboxGroupComponent,
    UserEditFormComponent,
    AssetsListWrapperComponent,
    AssetsFilterComponent,
    AssetsListComponent,
    AssetListItemComponent,
    AssetCardComponent,
    AssetVersionCardComponent,
    AssetStatusLabelComponent,
    DateBadgeComponent,
    AssetVersionCardComponent,
    TagsComponent,
    NoDataAvailableComponent,
    PageIsUnderDevelopmentComponent,
    AssetPriceTagComponent,
    UsersTableComponent,
];
const pipes: any[] = [TimeAgoPipe, FileSizePipe, AvatarUrlPipe, AssetLogoUrlPipe, RedirectLinkPipe];

const directives: any[] = [IsAuthorizedDirective];

@NgModule({
    declarations: [
        ...components,
        ...pipes,
        ...directives,
        RejectReasonModalComponent,
        UpgradeVersionModalComponent,
        DownloadFilesModalComponent,
    ],
    imports: [...modules],
    entryComponents: [],
    exports: [...modules, ...components, ...pipes, ...directives],
    providers: [ReviewModalService],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}
