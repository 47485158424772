<div class="m-4 font-14px" *transloco="let t; read: 'assets.card'">
    <a [routerLink]="(isManageAsset ? '/manage-assets/' : '/assets/') + assetId + '/version/' + assetVersion.versionId"
       class="h1 d-flex mb-0 width-fit-content"
    >
        {{ assetVersion.version }}
    </a>
    <app-date-badge [date]="assetVersion.createdAt"></app-date-badge>
    <app-tags [tags]="assetVersion.tags"></app-tags>
    <div class="d-flex mt-8px">
        <div class="disable min-width-80px">{{ t('description') }}:</div>
        <div class="ml-1 flex-1">
            {{ assetVersion.description }}
        </div>
    </div>
    <div class="d-flex mt-8px">
        <div class="disable min-width-80px">{{ t('overview') }}:</div>
        <div class="ml-1 flex-1">
            {{ assetVersion.overview }}
        </div>
    </div>
</div>
