import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DictionaryItem, DictionaryService } from '@app/shared/services/dictionary.service';
import { TranslationKeysOfTextarea } from '@dagility-ui/kit';
import { TranslationService } from '@app/core/services/translation.service';

export interface RejectReason {
    resolutionId: number;
    comment: string;
}

@Component({
    selector: 'app-reject-reason-modal',
    templateUrl: './reject-reason-modal.component.html',
})
export class RejectReasonModalComponent implements OnInit {
    resolutions$: Observable<DictionaryItem[]> = this.dictionaryService.getRejectReasons();

    form: FormGroup;

    private _textareaTranslatedText: Partial<Record<TranslationKeysOfTextarea, string>>;

    get textareaTranslatedText(): Partial<Record<TranslationKeysOfTextarea, string>> {
        return this._textareaTranslatedText;
    }

    constructor(
        private dictionaryService: DictionaryService,
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private translationService: TranslationService
    ) {
        translationService.translateObject('common.textarea').subscribe(
            textarea =>
                (this._textareaTranslatedText = {
                    CHARACTERS_LEFT: textarea.charactersLeft,
                })
        );
    }

    ngOnInit() {
        this.form = this.fb.group({
            resolutionId: [null, Validators.required],
            comment: [''],
        });
    }

    reject() {
        if (this.form.invalid) {
            return;
        }
        const formValue = this.form.getRawValue();
        const rejectReason: RejectReason = {
            resolutionId: formValue.resolutionId,
            comment: formValue.comment,
        };

        this.activeModal.close(rejectReason);
    }
}
