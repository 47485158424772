import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MetadataFieldDto, MetadataFieldWithValuesDto, MetadataSlimDto } from '@app/shared/models/metadata.model';
import { environment } from '@app/env';
import { map } from 'rxjs/operators';
import { Id } from '@app/shared/services/assets.service';

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    readonly metadata$: BehaviorSubject<MetadataFieldWithValuesDto[]> = new BehaviorSubject<MetadataFieldWithValuesDto[]>([]);
    readonly metaDataUrl = environment.metaDataApiURL;

    constructor(private http: HttpClient) {
        this.http
            .get<MetadataFieldWithValuesDto[]>(`${this.metaDataUrl}/system`)
            .pipe(
                map(metadata => {
                    metadata.sort((a, b) => a.order - b.order);
                    return metadata;
                })
            )
            .subscribe(metadata => this.metadata$.next(metadata));
    }

    getMetadataTypesByCategoryId(categoryId: Id): Observable<MetadataSlimDto[]> {
        return this.http.get<MetadataSlimDto[]>(`${this.metaDataUrl}`, {
            params: {
                categoryId,
            },
        });
    }

    getMetadataFieldsById(id: number): Observable<MetadataFieldDto[]> {
        return this.http.get<MetadataFieldDto[]>(`${this.metaDataUrl}/${id}/fields`);
    }

    getMetadataFieldsByCategoryId(categoryId: Id): Observable<MetadataFieldWithValuesDto[]> {
        return this.http.get<MetadataFieldWithValuesDto[]>(`${this.metaDataUrl}/category`, { params: { categoryId } });
    }

    getMetadataFieldValues(metadataId: number, id: number): Observable<string[]> {
        return this.http.get<string[]>(`${this.metaDataUrl}/${metadataId}/fields/${id}/values`);
    }
}
