import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { UserRoleBadgeComponent } from './components/user-role-badge/user-role-badge.component';
import { HeaderNavigationComponent } from './components/header-navigation/header-navigation.component';
import { HeaderNavigationDropdownComponent } from './components/header-navigation/header-navigation-dropdown/header-navigation-dropdown.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
    declarations: [UserRoleBadgeComponent, HeaderNavigationComponent, HeaderNavigationDropdownComponent],
    imports: [SharedModule, TranslocoModule],
    exports: [HeaderNavigationComponent, UserRoleBadgeComponent],
    providers: [],
})
export class HeaderModule {}
