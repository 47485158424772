import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SidenavItems } from '@dagility-ui/kit/modules/sidenav/types/sidenav-item';
import { AuthService } from '@app/auth';
import { isAdminRoleMostValuable, isGroupAdminRoleMostValuable, UserDto } from '@app/core/models/user.model';
import { groupsIcon, settingsIcon, suitcaseIcon, userIcon, usersIcon } from '@app/shared/icons/solutionhub-icons';
import { TranslationService } from '@app/core/services/translation.service';

const DEFAULT_SIDENAV_ITEMS: any = [
    {
        id: 'assets-tab',
        routerLink: '/assets',
        icon: [suitcaseIcon, [null, null]],
        translationKey: 'assets',
    },
    {
        id: 'manage-assets-tab',
        routerLink: '/manage-assets',
        icon: [settingsIcon, [null, null]],
        translationKey: 'manageAssets',
    },
    {
        id: 'profile-tab',
        routerLink: `profile`,
        icon: [userIcon, [null, null]],
        translationKey: 'profile',
    },
];

// const DASHBOARD_SIDENAV_ITEM: any = {
//     id: 'dashboard-tab',
//     routerLink: '/dashboard',
//     icon: [barChartIcon, [null, null]],
//     translationKey: 'manageAssets',
// };

const USER_MANAGEMENT_SIDENAV_ITEM: any = {
    id: 'user-management-tab',
    routerLink: '/users',
    icon: [usersIcon, [null, null]],
    translationKey: 'users',
};

const GROUP_MANAGEMENT_SIDENAV_ITEM: any = {
    id: 'group-management-tab',
    routerLink: '/groups',
    icon: [groupsIcon, [null, null]],
    translationKey: 'groups',
};
//
// const MESSAGES_SIDENAV_ITEM: any = {
//     id: 'messages-tab',
//     routerLink: '/messages',
//     icon: [bellIcon, [null, null]],
//     data: {
//         count: 5, // TODO: get count of unread messages from BE
//     },
//     translationKey: 'messages',
// };

@Injectable({
    providedIn: 'root',
})
export class SidenavService {
    sidenavItems$: Observable<SidenavItems>;

    constructor(private readonly authService: AuthService, private readonly translationService: TranslationService) {
        this.translationService.translateObject('sidenav').subscribe(translation => {
            const sidenavItems = this.createSideNavItems(this.authService.getUser());
            sidenavItems.forEach(sidenavItem => (sidenavItem.title = translation[sidenavItem.translationKey]));

            this.sidenavItems$ = of(sidenavItems);
        });

        this.authService.onAfterLogin$.subscribe(user => (this.sidenavItems$ = of(this.createSideNavItems(user))));
    }

    private createSideNavItems(user: UserDto) {
        if (!user) {
            return [];
        }

        const sidenavItems = [...DEFAULT_SIDENAV_ITEMS];

        // const isProducer = isProducerRoleMostValuable(user.roles);

        // if (isAdmin || isProducer) {
        //     sidenavItems.splice(1, 0, DASHBOARD_SIDENAV_ITEM);
        // }

        const isGroupAdmin = isGroupAdminRoleMostValuable(user.roles);
        if (isGroupAdmin) {
            sidenavItems.splice(2, 0, GROUP_MANAGEMENT_SIDENAV_ITEM);
        }

        const isAdmin = isAdminRoleMostValuable(user.roles);
        if (isAdmin) {
            sidenavItems.splice(2, 0, USER_MANAGEMENT_SIDENAV_ITEM, GROUP_MANAGEMENT_SIDENAV_ITEM);
        }

        return sidenavItems;
    }
}
