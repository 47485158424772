import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@app/shared/components/not-found/not-found.component';
import { LocationPermissionGuard } from '@app/core/services/location-permission.guard';
import { AccessDeniedComponent } from '@app/shared/components/access-denied/access-denied.component';
import { ROLE_ADMIN, ROLE_CONSUMER, ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN } from '@app/core/models/user.model';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    },
    {
        path: 'assets',
        loadChildren: () => import('./assets/assets.module').then(m => m.AssetsModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN, ROLE_ADMIN],
        },
    },
    {
        path: 'manage-assets',
        loadChildren: () => import('./manage-assets/manage-assets.module').then(m => m.ManageAssetsModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_CONSUMER, ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN, ROLE_ADMIN],
        },
    },
    {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_ADMIN],
        },
    },
    {
        path: 'groups',
        loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_USER_GROUP_ADMIN, ROLE_ADMIN],
        },
    },
    {
        path: 'profile',
        loadChildren: () => import('./users/user-profile-page/user-profile-page.module').then(m => m.UserProfilePageModule),
        canActivate: [LocationPermissionGuard],
    },
    {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_CONSUMER, ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN, ROLE_ADMIN],
        },
    },
    {
        path: 'about',
        loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
    },
    {
        path: 'community',
        loadChildren: () => import('./community/community.module').then(m => m.CommunityModule),
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent,
    },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
