import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { RoleDto, UserDto, UserRole, userRoleMap, UsersFilter } from '@app/core/models/user.model';
import { GridColumn, Page, Pageable, Pager, Pagination, sortingDown, sortingUp, TranslationKeysOfPagination } from '@dagility-ui/kit';
import { catchError, map, tap } from 'rxjs/operators';
import { TranslationService } from '@app/core/services/translation.service';
import { AuthService } from '@app/auth';
import { checkAllIcon, minus, userIcon } from '@app/shared/icons/solutionhub-icons';
import { UserGroupDto } from '@app/shared/services/user-group.service';

type UsersTableMode = 'user-management' | 'group-management' | 'select-users';

@Component({
    selector: 'app-users-table',
    templateUrl: './users-table.component.html',
    styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit {
    gridColumns: GridColumn[];

    @Input() set roles(roles: RoleDto[]) {
        if (this.mode === 'user-management') {
            this.translationService.translateObject('usersPage.columns').subscribe(
                columnsTranslation =>
                    (this.gridColumns = [
                        { title: '', width: '48px' },
                        {
                            title: columnsTranslation.firstName,
                            field: 'firstName',
                            sortingField: 'firstName',
                            width: '100px',
                        },
                        {
                            title: columnsTranslation.lastName,
                            field: 'lastName',
                            sortingField: 'lastName',
                            width: '100px',
                        },
                        { title: columnsTranslation.login, field: 'login', sortingField: 'login', width: '90px' },
                        {
                            title: columnsTranslation.group,
                            field: 'groupName',
                            sortingField: 'userGroup',
                            width: '90px',
                        },
                        { title: columnsTranslation.email, field: 'email', sortingField: 'email', width: '90px' },
                        {
                            title: columnsTranslation.submissionDate,
                            field: 'createdAt',
                            sortingField: 'createdAt',
                            width: '135px',
                        },
                        ...(roles?.map(role => ({
                            title: userRoleMap.get(role.key),
                            field: role.key,
                            width: '90px',
                        })) || []),
                        { title: columnsTranslation.actions, width: '80px' },
                    ])
            );
        }
    }

    private _mode: UsersTableMode = 'group-management';

    @Input() set mode(mode: UsersTableMode) {
        this._mode = mode;
        switch (this._mode) {
            case 'user-management':
                this.translationService.translateObject('usersPage.columns').subscribe(
                    columnsTranslation =>
                        (this.gridColumns = [
                            { title: '', width: '48px' },
                            {
                                title: columnsTranslation.firstName,
                                field: 'firstName',
                                sortingField: 'firstName',
                                width: '100px',
                            },
                            {
                                title: columnsTranslation.lastName,
                                field: 'lastName',
                                sortingField: 'lastName',
                                width: '100px',
                            },
                            { title: columnsTranslation.login, field: 'login', sortingField: 'login', width: '90px' },
                            {
                                title: columnsTranslation.group,
                                field: 'groupName',
                                sortingField: 'userGroup',
                                width: '90px',
                            },
                            { title: columnsTranslation.email, field: 'email', sortingField: 'email', width: '90px' },
                            {
                                title: columnsTranslation.submissionDate,
                                field: 'createdAt',
                                sortingField: 'createdAt',
                                width: '135px',
                            },
                            ...(this.roles?.map(role => ({
                                title: userRoleMap.get(role.key),
                                field: role.key,
                                width: '90px',
                            })) || []),
                            { title: columnsTranslation.actions, width: '80px' },
                        ])
                );
                break;
            case 'group-management':
                this.translationService.translateObject('groupsPage.columns').subscribe(
                    columnsTranslation =>
                        (this.gridColumns = [
                            { title: '', width: '48px' },
                            {
                                title: columnsTranslation.firstName,
                                field: 'firstName',
                                sortingField: 'firstName',
                                width: '100px',
                            },
                            {
                                title: columnsTranslation.lastName,
                                field: 'lastName',
                                sortingField: 'lastName',
                                width: '100px',
                            },
                            { title: columnsTranslation.login, field: 'login', sortingField: 'login', width: '90px' },
                            {
                                title: columnsTranslation.group,
                                field: 'groupName',
                                sortingField: 'userGroup',
                                width: '90px',
                            },
                            { title: columnsTranslation.email, field: 'email', sortingField: 'email', width: '90px' },
                            {
                                title: columnsTranslation.submissionDate,
                                field: 'createdAt',
                                sortingField: 'createdAt',
                                width: '135px',
                            },
                            {
                                title: columnsTranslation.roles,
                                field: 'roles',
                                width: '110px',
                            },
                            { title: columnsTranslation.actions, width: '125px' },
                        ])
                );
                break;
            case 'select-users':
                this.translationService.translateObject('groupsPage.columns').subscribe(
                    columnsTranslation =>
                        (this.gridColumns = [
                            {
                                title: columnsTranslation.group,
                                field: 'groupName',
                                sortingField: 'userGroup',
                                width: '90px',
                            },
                            { title: '', width: '48px' },
                            {
                                title: columnsTranslation.firstName,
                                field: 'firstName',
                                sortingField: 'firstName',
                                width: '100px',
                            },
                            {
                                title: columnsTranslation.lastName,
                                field: 'lastName',
                                sortingField: 'lastName',
                                width: '100px',
                            },
                            {
                                title: columnsTranslation.roles,
                                field: 'roles',
                                width: '110px',
                            },
                            { title: '', width: '90px' },
                            { title: columnsTranslation.actions, width: '125px' },
                        ])
                );
                break;
        }
    }

    get mode(): UsersTableMode {
        return this._mode;
    }

    @Input() filter$: BehaviorSubject<UsersFilter>;

    users$: Observable<UserDto[]>;

    @Input() set usersPage$(value: Observable<Page<UserDto>>) {
        this.users$ = value.pipe(
            tap(page => (this.pagination = Pagination.of(page))),
            map(page => page.content),
            catchError(() => of([]))
        );
    }

    pagination: Pagination;
    pageable: Pageable = new Pageable(0, 0, []);

    icons = { sortingUp, sortingDown, userIcon, minus, checkAllIcon };

    get currentUser() {
        return this.authService.getUser();
    }

    get isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    @Input() clearUserRoleChanges: Subject<any> = new Subject<any>();

    userRoleChanges: Record<string, UserRole[]> = {};
    @Output() userRoleChanged: EventEmitter<Record<string, UserRole[]>> = new EventEmitter();

    @Input() canAddUser: boolean = false;
    @Input() canRemoveUser: boolean = false;
    @Output() addUser: EventEmitter<UserDto> = new EventEmitter();
    @Output() removeUser: EventEmitter<UserDto> = new EventEmitter();

    @Input() defaultGroup: UserGroupDto;
    @Input() targetGroup: UserGroupDto;

    private addedUsers: string[] = [];

    private _paginationTranslatedText: Partial<Record<TranslationKeysOfPagination, string>>;
    get paginationTranslatedText(): Partial<Record<TranslationKeysOfPagination, string>> {
        return this._paginationTranslatedText;
    }

    constructor(private authService: AuthService, private translationService: TranslationService) {}

    ngOnInit(): void {
        this.translationService.translateObject('common.pagination').subscribe(
            pagination =>
                (this._paginationTranslatedText = {
                    SHOW: pagination.show,
                    ENTRIES: pagination.entries,
                    SHOWING: pagination.showing,
                    TO: pagination.to,
                    OF: pagination.of,
                    JUMP_TO: pagination.jumpTo,
                })
        );
        this.clearUserRoleChanges.subscribe(() => {
            this.userRoleChanges = {};
            this.userRoleChanged.emit(this.userRoleChanges);
        });
    }

    hasCurrentUserInTable(users: UserDto[]): boolean {
        return users.map(user => user.id).includes(this.currentUser.id);
    }

    isUserAdded(user: UserDto): boolean {
        return this.addedUsers.includes(user.id);
    }

    rolesAsString(roles: UserRole[]): string {
        return roles.map(role => userRoleMap.get(role)).join(', ');
    }

    onPageChange(data: Pager) {
        this.filter$.next({
            ...this.filter$.value,
            page: data.currentPage - 1,
            size: data.pageSize,
        });
    }

    sortClick(fieldName: string) {
        if (!fieldName) {
            return;
        }
        this.pageable.sortOneField(fieldName);
        this.filter$.next({
            ...this.filter$.value,
            sort: this.pageable.orders.map(order => `${order.property},${order.direction}`),
        });
    }

    updateUserRole(user: UserDto, role: UserRole) {
        if (!this.userRoleChanges[user.id]) {
            this.userRoleChanges[user.id] = user.roles.slice();
        }
        const userRoleChange = this.userRoleChanges[user.id];
        const roleIndex = userRoleChange.indexOf(role);
        if (roleIndex === -1) {
            userRoleChange.push(role);
        } else {
            userRoleChange.splice(roleIndex, 1);
        }

        const rolesToStr = (roles: UserRole[]) => roles.sort() && roles.join('');
        if (rolesToStr(user.roles) === rolesToStr(userRoleChange)) {
            delete this.userRoleChanges[user.id];
        }
        this.userRoleChanged.emit(this.userRoleChanges);
    }

    addUserEmit(user: UserDto) {
        this.addedUsers.push(user.id);
        this.addUser.emit(user);
    }

    removeUserEmit(user: UserDto) {
        this.addedUsers = this.addedUsers.filter(userId => userId !== user.id);
        this.removeUser.emit(user);
    }
}
