import { Component, forwardRef, Input, OnDestroy, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ICheckboxGroupItem } from './checkbox-group.interface';

declare const _: any;

interface IValues {
    [key: string]: boolean | undefined;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'checkbox-group',
    templateUrl: './checkbox-group.component.html',
    styleUrls: ['./checkbox-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxGroupComponent),
            multi: true,
        },
    ],
})
export class CheckboxGroupComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
    @Input() items: ICheckboxGroupItem[];
    @Input() disabled: boolean;
    @Input() blueCheck: boolean;
    @Input() isInline = false;
    @Input() undefinedState = false;
    @Input() buttonView = false;

    @Output() change = new EventEmitter<any>();

    groupControl: FormGroup;

    private subscription = new Subscription();

    private value: IValues = {};
    private touched: boolean;
    private onChange: any;
    private onTouched: any;

    constructor() {}

    ngOnInit() {
        this.touched = false;

        this.createControl();
    }

    ngOnChanges(): void {
        this.createControl();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(obj: any) {
        if (obj) {
            this.value = _.clone(obj);
            this.groupControl.patchValue(this.value);
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.createControl();
    }

    private subscribeValueChanges(): void {
        this.subscription.unsubscribe();
        this.subscription = this.groupControl.valueChanges.subscribe(() => {
            const val = this.groupControl.getRawValue();
            if (this.onChange) {
                this.onChange(val);
                this.change.emit(val);
            }
            if (this.onTouched) {
                this.onTouched();
            }
        });
    }

    private createControl(): void {
        const groupArgument: any = {};
        if (this.items) {
            this.items.forEach(item => {
                groupArgument[item.value] = new FormControl({
                    value: this.value[item.value],
                    disabled: item.disabled || this.disabled,
                });
            });
        }

        this.groupControl = new FormGroup(groupArgument);
        if (this.value) {
            this.groupControl.patchValue(this.value);
        }
        this.subscribeValueChanges();
    }
}
