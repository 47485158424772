import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class RouterStateHistory {
    private bufferSize = 50;
    private history$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    private previousUrl$: Subject<string> = new Subject();

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route: ActivatedRoute) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                switchMap(route => route.data),
                map((data: any) => data.title)
            )
            .subscribe((title: string) => {
                this.titleService.setTitle(`SolutionHub${title ? ` - ${title}` : ''}`);
            });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((urlNavigationEnd: NavigationEnd) => {
            const historyArray = this.history$.value;
            historyArray.push(urlNavigationEnd.urlAfterRedirects);

            if (historyArray.length > this.bufferSize) {
                historyArray.shift();
            }
            this.history$.next(this.history$.value);
            this.previousUrl$.next(historyArray.length > 1 ? historyArray[historyArray.length - 2] : '');
        });
    }

    getHistory(): Observable<string[]> {
        return this.history$.asObservable();
    }

    getPreviousUrl(): Observable<string> {
        return this.previousUrl$.asObservable();
    }
}
