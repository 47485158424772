import { AfterViewInit, Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AssetActionResponse, AssetDto, AssetEvent } from '../../../asset.model';
import { StateMachineService } from '@app/shared/services/state-machine.service';
import { ModalService } from '@dagility-ui/kit';
import {
    RejectReason,
    RejectReasonModalComponent,
} from '@app/shared/components/assets/assets-list-wrapper/assets-list/asset-list-item/reject-reason-modal/reject-reason-modal.component';
import { FileService } from '@app/shared/services/file.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UpgradeVersionModalComponent } from '@app/shared/components/upgrade-version-modal/upgrade-version-modal.component';
import { AuthService } from '@app/auth';
import { UserDto } from '@app/core/models/user.model';

@Component({
    selector: 'app-asset-list-item',
    templateUrl: './asset-list-item.component.html',
    styleUrls: ['./asset-list-item.component.scss'],
})
export class AssetListItemComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    readonly events = AssetEvent;

    @Input() item: AssetDto;

    @Input() hasManagementActions: boolean;

    @Input() isManageAssets: boolean;

    @Input() showStatus = false;

    @Input() readOnly = false;

    @Input() isLoggedIn = false;

    @Input() isGridView = false;

    hideButtonText: boolean = false;
    private resizeObserver: ResizeObserver;
    private itemEl: HTMLElement;
    private cardEl: HTMLElement;
    private actionsEl: HTMLElement;

    private assetActionsWidth: number;

    isDownloading(uuid: string): boolean {
        return this.fileService.isDownloading(uuid);
    }

    get currentUser(): UserDto {
        return this.authService.getUser();
    }

    get isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    constructor(
        public stateMachineService: StateMachineService,
        private modalService: ModalService,
        private fileService: FileService,
        private router: Router,
        private route: ActivatedRoute,
        public authService: AuthService,
        private zone: NgZone
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.isGridView && changes.isGridView.currentValue !== changes.isGridView.previousValue) {
            this.hideButtonText = false;
        }
    }

    ngOnInit(): void {
        this.resizeObserver = new ResizeObserver(entries => {
            entries.forEach(() => {
                this.assetActionsWidth = this.actionsEl.className.includes('hide-button-text')
                    ? this.assetActionsWidth
                    : this.actionsEl.getBoundingClientRect().width;
                const itemWidth: number = this.itemEl.getBoundingClientRect().width - 49;
                const cardWidth: number = 500;
                const actionsWidth: number = this.assetActionsWidth;
                const hideButtonText: boolean =
                    (this.isGridView && itemWidth <= actionsWidth) || (!this.isGridView && itemWidth <= cardWidth + actionsWidth);
                if (hideButtonText !== this.hideButtonText) {
                    this.zone.run(() => {
                        this.hideButtonText = hideButtonText;
                    });
                }
            });
        });
    }

    ngAfterViewInit(): void {
        this.hideButtonText = false;
        this.itemEl = document.getElementById(`asset-item-${this.item.id}-${this.item.assetVersionId}`);
        this.cardEl = document.getElementById(`asset-card-${this.item.id}-${this.item.assetVersionId}`);
        this.actionsEl = document.getElementById(`asset-actions-${this.item.id}-${this.item.assetVersionId}`);
        this.assetActionsWidth = this.actionsEl.getBoundingClientRect().width;
        this.resizeObserver.observe(this.itemEl);
    }

    ngOnDestroy(): void {
        this.resizeObserver.observe(this.itemEl);
    }

    handleReject() {
        const modalRef = this.modalService.open(RejectReasonModalComponent, {
            centered: true,
            backdrop: 'static',
            size: 'lg',
        });

        modalRef.result.then(
            (rejectReason: RejectReason) => {
                this.stateMachineService
                    .handleAssetSlimEvent(this.item, AssetEvent.ADMIN_REJECT, rejectReason)
                    .subscribe((response: AssetActionResponse) => (this.item.status = response.newStatus));
            },
            () => {}
        );
    }

    handleEvent(event: AssetEvent) {
        this.stateMachineService
            .handleAssetSlimEvent(this.item, event)
            .subscribe((response: AssetActionResponse) => (this.item.status = response.newStatus));
    }

    handleDownload(asset: AssetDto) {
        this.fileService.downloadAssetDownloadable(asset);
    }

    handleUpgradeVersion() {
        const modalRef = this.modalService.open(
            UpgradeVersionModalComponent,
            {
                centered: true,
                backdrop: 'static',
                size: 'lg',
            },
            {
                asset: this.item,
            }
        );

        modalRef.result.then(
            (response: AssetActionResponse) => {
                if (!response) {
                    return;
                }

                this.router.navigate([this.item.id, response.versionId], { relativeTo: this.route }).then();
            },
            () => {}
        );
    }
}
