import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { AssetDto, FileType } from '@app/shared/components/assets/asset.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ModalService, writeContents } from '@dagility-ui/kit';
import { Download, download, DownloadState } from '@app/shared/models/download.model';
import { DownloadFilesModalComponent } from '@app/shared/components/download-files-modal/download-files-modal.component';

export const DOWNLOAD_SELECTED_PREFIX: string = 'download_selected:';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    readonly fileUrl = environment.fileURL;

    private _downloads: Map<string, Download> = new Map<string, Download>();
    private get downloads(): ReadonlyMap<string, Download> {
        return this._downloads;
    }

    private _isFileUploading$ = new BehaviorSubject<boolean>(false);
    get isFileUploading$(): BehaviorSubject<boolean> {
        return this._isFileUploading$;
    }

    private _downloadingFiles$ = new BehaviorSubject<ReadonlyMap<string, Download>>(this.downloads);
    get downloadingFiles$(): BehaviorSubject<ReadonlyMap<string, Download>> {
        return this._downloadingFiles$;
    }

    constructor(private http: HttpClient, private modalService: ModalService) {}

    downloadFile(uuid: string, versionId: number, name: string): Observable<Download> {
        return this.http
            .get(`${this.fileUrl}/download/${uuid}`, {
                params: versionId ? { versionId } : {},
                reportProgress: true,
                observe: 'events',
                responseType: 'blob',
            })
            .pipe(
                download(blob => writeContents(blob, name)),
                tap(
                    d => {
                        this.setDownload(uuid, d);
                    },
                    () => {
                        this.deleteDownload(uuid);
                    },
                    () => {
                        this.deleteDownload(uuid);
                    }
                )
            );
    }

    downloadSelected(uuids: string[], versionId: number, name: string): Observable<Download> {
        return this.http
            .get(`${this.fileUrl}/download/zip`, {
                params: versionId ? { versionId, uuids } : { uuids },
                reportProgress: true,
                observe: 'events',
                responseType: 'blob',
            })
            .pipe(
                download(blob => writeContents(blob, name)),
                tap(
                    d => {
                        this.setDownload(`${DOWNLOAD_SELECTED_PREFIX}${versionId}`, d);
                    },
                    () => {
                        this.deleteDownload(`${DOWNLOAD_SELECTED_PREFIX}${versionId}`);
                    },
                    () => {
                        this.deleteDownload(`${DOWNLOAD_SELECTED_PREFIX}${versionId}`);
                    }
                )
            );
    }

    uploadFile(file: File, fileType: FileType): Observable<string[]> {
        const formData = new FormData();
        formData.append('type', fileType);
        formData.append('files', file);

        this.isFileUploading$.next(true);
        return this.http.post<string[]>(`${this.fileUrl}`, formData).pipe(
            tap(
                () => {},
                () => this.isFileUploading$.next(false),
                () => this.isFileUploading$.next(false)
            )
        );
    }

    deleteFile(uuid: string, versionId: number) {
        return this.http.delete<string[]>(`${this.fileUrl}/${uuid}`, {
            params: versionId ? { versionId } : {},
        });
    }

    downloadAssetDownloadable(asset: AssetDto) {
        const modalRef = this.modalService.open(
            DownloadFilesModalComponent,
            {
                backdrop: 'static',
                centered: true,
                size: 'sm',
            },
            { asset }
        );

        modalRef.result.then(
            () => {},
            () => {}
        );
    }

    isDownloading(uuid: string) {
        return [DownloadState.PENDING, DownloadState.IN_PROGRESS].includes(this.downloadingFiles$.value.get(uuid)?.state);
    }

    getDownloading(uuid: string) {
        return this.downloadingFiles$.value.get(uuid);
    }

    private setDownload(uuid: string, downloadingFile: Download) {
        this._downloads.set(uuid, downloadingFile);
        this.notifyDownloadingFiles();
    }

    private deleteDownload(uuid: string) {
        this._downloads.delete(uuid);
        this.notifyDownloadingFiles();
    }

    private notifyDownloadingFiles() {
        this._downloadingFiles$.next(this.downloads);
    }
}
