import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { facChevronDown, facChevronLeft, facChevronRight, facChevronUp } from '@dagility-ui/kit';
import { Router } from '@angular/router';
import { DropdownItem } from './header-navigation-dropdown/header-navigation-dropdown.component';

@Component({
    selector: 'app-header-navigation',
    templateUrl: './header-navigation.component.html',
    styleUrls: ['./header-navigation.component.scss'],
})
export class HeaderNavigationComponent implements OnInit {
    @Input() activeItem: string = null;
    @Input() navItems$: Observable<NavItem[]> = of([]);
    @Input() collapsed$: Subject<boolean> = new Subject<boolean>();

    activeDropdown: string = null;
    dropdownItems: DropdownItem[] = [];

    topOffset = 0;

    collapsed: boolean;

    icons = {
        chevronDown: facChevronDown,
        chevronUp: facChevronUp,
        chevronRight: facChevronRight,
        chevronLeft: facChevronLeft,
    };

    collapsedNavOpened: boolean = false;

    ngOnInit(): void {
        this.collapsed$.subscribe(collapsed => {
            this.collapsed = collapsed;
            this.collapsedNavOpened = false;
            this.activeItem = null;
            this.activeDropdown = null;
        });
    }

    constructor(private router: Router) {}

    toggleItem(item: NavItem, i: number, event: MouseEvent) {
        event.stopPropagation();
        this.activeItem = this.activeItem !== `nav-item-${i}` ? `nav-item-${i}` : null;
        if (item.children?.length > 0) {
            this.dropdownItems = item.children;
            this.activeDropdown = this.activeDropdown !== `dropdown-${i}` ? `dropdown-${i}` : null;
            this.topOffset = (event.target as HTMLElement).getBoundingClientRect().bottom;
        } else {
            this.router.navigate([item.link]).then();
        }
    }

    openNav() {
        this.collapsedNavOpened = !this.collapsedNavOpened;
    }

    clickHandler(event: any, target: HTMLElement) {
        if (!target.contains(event.target)) {
            this.closeAll();
        }
    }

    closeAll() {
        this.activeItem = null;
        this.activeDropdown = null;
        this.collapsedNavOpened = false;
    }
}

export interface NavItem {
    title: string;
    label: string;
    link: string;
    position: number;
    children: DropdownItem[];
}
