<app-header></app-header>
<app-progress-bar></app-progress-bar>
<lib-sidenav-container *ngIf="{ user: user$ | async } as vm" id="main-container-id" class="app-main-wrapper">
    <lib-sidenav *ngIf="vm.user" #sidenav [items]="sidenavItems$ | async">
        <ng-template libSidenavHeader>
            <div class="app-sidenav-header" [style.display]="!sidenav.expanded ? 'none' : ''">
                <lib-user-pic
                    [user]="{
                        firstName: vm.user.firstName,
                        lastName: vm.user.lastName,
                        imagePath: vm.user | avatarUrl
                    }"
                    [size]="32"
                ></lib-user-pic>
                <span *ngIf="vm.user && sidenav.expanded" class="ml-10px">{{ vm.user.firstName + ' ' + vm.user.lastName }}</span>
            </div>
        </ng-template>
        <ng-template libSidenavItemPostfix let-item="item">
            <div *ngIf="item.data?.count" class="custom-badge size-s" [class.custom-badge--collapsed]="!sidenav.expanded">
                {{ item.data.count }}
            </div>
        </ng-template>
    </lib-sidenav>

    <lib-sidenav-content class="sidenav-content" [class.sidenav-content-without-sidenav]="!vm.user">
        <router-outlet *ngIf="assetEventsInit$ | async; else loadingTmpl"></router-outlet>

        <ng-template #loadingTmpl>
            <div class="d-flex justify-content-center align-items-center h-100">
                <lib-spinner></lib-spinner>
            </div>
        </ng-template>
    </lib-sidenav-content>
</lib-sidenav-container>
