import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { MarketplaceTemplateRating } from '@dagility-ui/kit';
import { AssetDto } from '../asset.model';
import { ReviewModalService } from '@app/shared/services/review-modal.service';
import { TranslationService } from '@app/core/services/translation.service';
import { TranslationKeysOfRating } from '@dagility-ui/kit/modules/forms/controls/rating/rating.component';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-asset-card',
    templateUrl: './asset-card.component.html',
    styleUrls: ['./asset-card.component.scss'],
})
export class AssetCardComponent implements OnInit, OnChanges, OnDestroy {
    @Input() asset: AssetDto;

    @Input() expanded = false;

    @Input() showStatus = false;

    @Input() isInList = false;

    @Input() canLeaveReview = false;

    @Input() isManageAsset: boolean = false;

    @Input() isGridView = false;

    ratingStatistics$: Observable<MarketplaceTemplateRating>;

    translatedRatingText: Partial<Record<TranslationKeysOfRating, string>>;

    private asset$: BehaviorSubject<AssetDto>;

    private destroy$ = new Subject();

    constructor(private reviewModalService: ReviewModalService, private translationService: TranslationService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.asset && changes.asset.previousValue !== changes.asset.currentValue) {
            this.asset$?.next(changes.asset.currentValue);
        }
    }

    ngOnInit(): void {
        this.asset$ = new BehaviorSubject<AssetDto>(this.asset);

        const getRate = (asset: AssetDto, stars: number) => {
            if (!asset.reviewCountByMark) {
                return 0;
            }
            return asset.reviewCountByMark[stars] || 0;
        };
        this.ratingStatistics$ = this.asset$.pipe(
            takeUntil(this.destroy$),
            switchMap(asset =>
                of({
                    rate1: getRate(asset, 1),
                    rate2: getRate(asset, 2),
                    rate3: getRate(asset, 3),
                    rate4: getRate(asset, 4),
                    rate5: getRate(asset, 5),
                    downloads: asset.downloadCount,
                    rating: asset.averageScore,
                    myRating: 1,
                    ratesCount: asset.reviewCount,
                })
            )
        );

        this.translationService.translateObject('assets.card.rating').subscribe(
            ratingText =>
                (this.translatedRatingText = {
                    USER_RATINGS: ratingText.userRatings,
                    DOWNLOADS: ratingText.downloads,
                    STAR: ratingText.star,
                    STARS: ratingText.stars,
                })
        );
    }

    writeReview() {
        this.reviewModalService.writeReview(this.asset);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
