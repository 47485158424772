import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    isDarkTheme$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isDarkTheme);

    private get isDarkTheme(): boolean {
        return document.documentElement.dataset.theme && document.documentElement.dataset.theme.includes('dark');
    }

    setLightTheme() {
        document.documentElement.dataset.theme = 'light-ust';
        this.isDarkTheme$.next(this.isDarkTheme);
    }

    setDarkTheme() {
        document.documentElement.dataset.theme = 'dark-ust';
        this.isDarkTheme$.next(this.isDarkTheme);
    }

    toggleTheme() {
        this.isDarkTheme$.next(!this.isDarkTheme$.value);
        document.documentElement.dataset.theme = this.isDarkTheme$.value ? 'dark-ust' : 'light-ust';
    }
}
