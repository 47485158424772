<form [formGroup]="form" (reset)="activeModal.dismiss('Cancel')" (submit)="upgrade()">
    <ng-container *transloco="let transloco; read: 'upgradeVersionModal'">
        <div class="modal-header">
            <h1 class="mt-0">{{ transloco('title') }}</h1>
            <button type="reset" class="close" aria-label="Close button" aria-describedby="modal-title">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="d-flex flex-column">
                <lib-input
                    class="mb-4 w-100"
                    formControlName="assetVersion"
                    [label]="('assetEditor.' + (asset.assetType | lowercase) + '.generalForm.assetVersion') | transloco"
                ></lib-input>
            </div>
        </div>

        <div class="modal-footer">
            <button type="reset" class="btn btn-secondary mr-25px ml-auto">
                {{ transloco('cancelBtn') }}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="form.invalid" [btnWithLoader]="loading">
                {{ transloco('upgradeBtn') }}
            </button>
        </div>
    </ng-container>
</form>
