import { Component, Input } from '@angular/core';
import { AssetVersionSlimDto } from '@app/shared/components/assets/asset.model';

@Component({
    selector: 'app-asset-version-card',
    templateUrl: './asset-version-card.component.html',
    styleUrls: ['./asset-version-card.component.scss'],
})
export class AssetVersionCardComponent {
    @Input() assetId: number;
    @Input() assetVersion: AssetVersionSlimDto;
    @Input() isManageAsset: boolean = false;
}
