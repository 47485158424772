import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Category } from '@app/shared/models/category.model';
import { environment } from '@app/env';
import { HttpClient } from '@angular/common/http';
import { DropdownItem } from '@dagility-ui/kit';

export interface DictionaryItem {
    id: number;
    name: string;
}

export interface SystemDictionaryItem {
    id: number;
    label: string;
}

const BUSINESS_ROLES: DropdownItem[] = [
    {
        label: 'CTO',
        value: 'CTO',
    },
];

const INDUSTRIES: DropdownItem[] = [
    {
        label: 'Finance',
        value: 'Finance',
    },
    {
        label: 'Test',
        value: 'Test',
    },
    {
        label: 'Test 2',
        value: 'Test 2',
    },
];

const REGULATIONS: DropdownItem[] = [
    {
        label: 'SOC3',
        value: 'SOC3',
    },
];

@Injectable({
    providedIn: 'root',
})
export class DictionaryService {
    readonly serviceUrl = environment.serviceUrl;

    readonly categories$: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);

    private readonly path = `${environment.categoriesApiURL}`;

    constructor(private http: HttpClient) {
        this.http.get<Category[]>(this.path).subscribe(categories => this.categories$.next(categories));
    }

    getPublishers(): Observable<DictionaryItem[]> {
        return this.http.get<DictionaryItem[]>(`${this.serviceUrl}/groups`);
    }

    getSearchKeywords(): Observable<DictionaryItem[]> {
        return this.http.get<DictionaryItem[]>(`${this.serviceUrl}/search-keywords`);
    }

    addSearchKeyword(keyword: string): Observable<any> {
        return this.http.post(`${this.serviceUrl}/search-keywords`, keyword);
    }

    getBusinessRoles(): Observable<DropdownItem[]> {
        return of(BUSINESS_ROLES);
    }

    getIndustries(): Observable<DropdownItem[]> {
        return of(INDUSTRIES);
    }

    getRegulations(): Observable<DropdownItem[]> {
        return of(REGULATIONS);
    }

    getRejectReasons(): Observable<DictionaryItem[]> {
        return this.http.get<DictionaryItem[]>(`${this.serviceUrl}/rejection-resolutions`);
    }
}
