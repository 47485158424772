import { Component, Input } from '@angular/core';
import { AssetDto } from '../../asset.model';

@Component({
    selector: 'app-assets-list',
    templateUrl: './assets-list.component.html',
    styleUrls: ['./assets-list.component.scss'],
})
export class AssetsListComponent {
    @Input() assets: AssetDto[] = [];

    @Input() hasManagementActions: boolean;

    @Input() isManageAssets: boolean;

    @Input() showStatus = false;

    @Input() readOnly = false;

    @Input() isLoggedIn = false;

    @Input() isGridView = false;
}
