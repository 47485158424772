import { HttpClient } from '@angular/common/http';
import { environment } from '@app/env';
import { Page } from '@dagility-ui/kit';
import { Observable } from 'rxjs';
import {
    AssetActionResponse,
    AssetCreationDto,
    AssetDto,
    AssetStatus,
    AssetType,
    AssetVersionSlimDto,
} from '../components/assets/asset.model';
import { ReviewDto, ReviewSlimDto } from '@app/shared/models/review.model';

export type Id = number | string;

export interface AssetFilter {
    searchTerm: string;
    pageSize: number;
    page: number;
    sort: string;
    status?: AssetStatus;
    categoryId?: Id;
    groupIds?: Id[];
    dropdownIds?: Id[];
    fieldValues?: Id[];
}

export interface AssetVersionDependency {
    versionId: number;
    version: string;
    name: string;
}

export class AssetsService {
    baseUrl = environment.assetsApiURL;

    constructor(protected http: HttpClient, private status: 'latest' | 'launched') {}

    get({
        page,
        pageSize,
        sort,
        status,
        searchTerm,
        categoryId,
        groupIds,
        dropdownIds,
        fieldValues,
    }: AssetFilter): Observable<Page<AssetDto>> {
        return this.http.post<Page<AssetDto>>(
            `${this.baseUrl}/${this.status}`,
            {
                status,
                searchTerm,
                categoryId,
                ...(groupIds?.length ? { groupIds } : {}),
                ...(dropdownIds?.length ? { dropdownIds } : {}),
                ...(fieldValues?.length ? { fieldValues } : {}),
            },
            {
                params: {
                    size: pageSize,
                    page,
                    sort,
                },
            }
        );
    }

    getLaunchedAssetsCount({ searchTerm, categoryId, groupIds, dropdownIds, fieldValues }: AssetFilter): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}/launched/count`, {
            searchTerm,
            categoryId,
            ...(groupIds?.length ? { groupIds } : {}),
            ...(dropdownIds?.length ? { dropdownIds } : {}),
            ...(fieldValues?.length ? { fieldValues } : {}),
        });
    }

    getById(id: string, versionId: string): Observable<AssetDto> {
        return this.http.get<AssetDto>(`${this.baseUrl}/${id}/versions/${versionId}`);
    }

    getFormById(id: number, assetVersionId: number): Observable<AssetCreationDto> {
        return this.http.get<AssetCreationDto>(`${this.baseUrl}/${id}/versions/${assetVersionId}/form`);
    }

    upgradeVersion(id: number, version: string): Observable<AssetActionResponse> {
        return this.http.post<AssetActionResponse>(`${this.baseUrl}/${id}/upgrade-version`, {}, { params: { upgradedVersion: version } });
    }

    getAssetVersions(id: number): Observable<AssetVersionSlimDto[]> {
        return this.http.get<AssetVersionSlimDto[]>(`${this.baseUrl}/${id}/versions`);
    }

    deleteAssetVersion(id: number, versionId: number) {
        return this.http.delete(`${this.baseUrl}/${id}/versions/${versionId}`);
    }

    getReviews(id: number, versionId: number, filter: AssetFilter): Observable<Page<ReviewSlimDto>> {
        return this.http.get<Page<ReviewSlimDto>>(`${this.baseUrl}/${id}/versions/${versionId}/review`, {
            params: {
                size: filter.pageSize,
                page: filter.page,
                sort: filter.sort,
            },
        });
    }

    createReview(id: number, versionId: number, review: ReviewDto) {
        return this.http.post(`${this.baseUrl}/${id}/versions/${versionId}/review`, review);
    }

    availableDependencies(assetType: AssetType, assetId?: number): Observable<AssetVersionDependency[]> {
        const params = assetId
            ? {
                  assetId,
                  assetType,
              }
            : { assetType };
        return this.http.get<AssetVersionDependency[]>(`${this.baseUrl}/dependencies`, { params });
    }

    dependencies(id: number, versionId: number, filter: AssetFilter): Observable<Page<AssetDto>> {
        return this.http.get<Page<AssetDto>>(`${this.baseUrl}/${id}/versions/${versionId}/dependency`, {
            params: {
                size: filter.pageSize,
                page: filter.page,
            },
        });
    }
}
