<div class="assets-list-container">
    <app-asset-list-item
        *ngFor="let item of assets"
        [item]="item"
        class="d-block"
        [class.mb-3]="!isGridView"
        [hasManagementActions]="hasManagementActions"
        [isManageAssets]="isManageAssets"
        [showStatus]="showStatus"
        [readOnly]="readOnly"
        [isLoggedIn]="isLoggedIn"
        [isGridView]="isGridView"
    ></app-asset-list-item>
</div>
