import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslationKeysOfTextarea } from '@dagility-ui/kit';
import { TranslationService } from '@app/core/services/translation.service';

const MAX_RATING = 5;

export interface ReviewModal {
    score: number;
    comment: string;
    advantages: string;
    disadvantages: string;
}

@Component({
    selector: 'app-review-modal',
    templateUrl: './review-modal.component.html',
    styleUrls: ['./review-modal.component.scss'],
})
export class ReviewModalComponent implements OnInit {
    ratingValue = 0;

    form: FormGroup;

    showValidationMessage: boolean = false;

    private _textareaTranslatedText: Partial<Record<TranslationKeysOfTextarea, string>>;

    get textareaTranslatedText(): Partial<Record<TranslationKeysOfTextarea, string>> {
        return this._textareaTranslatedText;
    }

    get stars() {
        return Array(MAX_RATING)
            .fill(0)
            .map((item, i) => i + 1);
    }

    get isValid(): boolean {
        return this.form.valid && this.ratingValue > 0 && this.ratingValue <= 5;
    }

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private translationService: TranslationService) {
        translationService.translateObject('common.textarea').subscribe(
            textarea =>
                (this._textareaTranslatedText = {
                    CHARACTERS_LEFT: textarea.charactersLeft,
                })
        );
    }

    ngOnInit() {
        this.form = this.fb.group({
            advantages: [''],
            disadvantages: [''],
            comment: [''],
        });
    }

    addReview() {
        if (!this.isValid) {
            this.showValidationMessage = true;
            return;
        }
        const formValue = this.form.getRawValue();

        const review: ReviewModal = {
            score: this.ratingValue,
            advantages: formValue.advantages,
            disadvantages: formValue.disadvantages,
            comment: formValue.comment,
        };

        this.activeModal.close(review);
    }
}
