import { Pipe, PipeTransform } from '@angular/core';
import { UserDto } from '@app/core/models/user.model';
import { environment } from '@app/env';

@Pipe({
    name: 'avatarUrl',
})
export class AvatarUrlPipe implements PipeTransform {
    transform(user: UserDto, type: 'NORMAL' | 'SMALL' = 'NORMAL') {
        if (!user.avatarExists) {
            return '';
        }

        let url = environment.usersApiURL + '/' + user.id + '/avatar?type=' + type;
        if (user.lastModifiedAt) {
            url += '&t=' + new Date(user.lastModifiedAt).getTime();
        }

        return url;
    }
}
