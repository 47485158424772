import { CustomIcon } from './types';

export const eyeOn =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M0.666992 8.00033C0.666992 8.00033 3.33366 2.66699 8.00033 2.66699C12.667 2.66699 15.3337 8.00033 15.3337 8.00033C15.3337 8.00033 12.667 13.3337 8.00033 13.3337C3.33366 13.3337 0.666992 8.00033 0.666992 8.00033Z" stroke="#BDBCBC" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8.00033 10.0003C9.10489 10.0003 10.0003 9.10489 10.0003 8.00033C10.0003 6.89576 9.10489 6.00033 8.00033 6.00033C6.89576 6.00033 6.00033 6.89576 6.00033 8.00033C6.00033 9.10489 6.89576 10.0003 8.00033 10.0003Z" stroke="#BDBCBC" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>';

export const facCheck =
    '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M19.9997 36.6663C29.2044 36.6663 36.6663 29.2044 36.6663 19.9997C36.6663 10.7949 29.2044 3.33301 19.9997 3.33301C10.7949 3.33301 3.33301 10.7949 3.33301 19.9997C3.33301 29.2044 10.7949 36.6663 19.9997 36.6663Z" fill="#2ECC71" stroke="#2ECC71" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M28.333 15L18.333 25.0167L13.333 20.0167" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>';

export const facDots =
    '<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M8.50033 8.66665C8.86852 8.66665 9.16699 8.36817 9.16699 7.99998C9.16699 7.63179 8.86852 7.33331 8.50033 7.33331C8.13214 7.33331 7.83366 7.63179 7.83366 7.99998C7.83366 8.36817 8.13214 8.66665 8.50033 8.66665Z" fill="#0097AB"/>' +
    '<path d="M13.167 8.66665C13.5352 8.66665 13.8337 8.36817 13.8337 7.99998C13.8337 7.63179 13.5352 7.33331 13.167 7.33331C12.7988 7.33331 12.5003 7.63179 12.5003 7.99998C12.5003 8.36817 12.7988 8.66665 13.167 8.66665Z" fill="#0097AB"/>' +
    '<path d="M3.83366 8.66665C4.20185 8.66665 4.50033 8.36817 4.50033 7.99998C4.50033 7.63179 4.20185 7.33331 3.83366 7.33331C3.46547 7.33331 3.16699 7.63179 3.16699 7.99998C3.16699 8.36817 3.46547 8.66665 3.83366 8.66665Z" fill="#0097AB"/>' +
    '<path d="M8.50033 8.66665C8.86852 8.66665 9.16699 8.36817 9.16699 7.99998C9.16699 7.63179 8.86852 7.33331 8.50033 7.33331C8.13214 7.33331 7.83366 7.63179 7.83366 7.99998C7.83366 8.36817 8.13214 8.66665 8.50033 8.66665Z" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M13.167 8.66665C13.5352 8.66665 13.8337 8.36817 13.8337 7.99998C13.8337 7.63179 13.5352 7.33331 13.167 7.33331C12.7988 7.33331 12.5003 7.63179 12.5003 7.99998C12.5003 8.36817 12.7988 8.66665 13.167 8.66665Z" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M3.83366 8.66665C4.20185 8.66665 4.50033 8.36817 4.50033 7.99998C4.50033 7.63179 4.20185 7.33331 3.83366 7.33331C3.46547 7.33331 3.16699 7.63179 3.16699 7.99998C3.16699 8.36817 3.46547 8.66665 3.83366 8.66665Z" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>';

export const facSkipped =
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#868484" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>';

export const facCircle =
    '<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M4.0013 7.33268C5.84225 7.33268 7.33464 5.8403 7.33464 3.99935C7.33464 2.1584 5.84225 0.666016 4.0013 0.666016C2.16035 0.666016 0.667969 2.1584 0.667969 3.99935C0.667969 5.8403 2.16035 7.33268 4.0013 7.33268Z" fill="white" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>';

export const facArrowInCircle =
    '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M4 6L6 8M6 8L8 6M6 8V4M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>';

export const facTransfer =
    '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<line x1="10.5" y1="3.75" x2="4.25" y2="3.75" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M8.75159 1.40039L10.8516 3.50039L8.75159 5.6004" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M3.24841 10.5996L1.14844 8.49961L3.24841 6.3996" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<line x1="7.75" y1="8.75" x2="1.5" y2="8.75" stroke="#0097AB" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>'

export const facGlobe: CustomIcon = {
    prefix: 'fac',
    iconName: 'facGlobe',
    icon: [
        14,
        14,
        [],
        '',
        'M7.00033 12.8334C10.222 12.8334 12.8337 10.2217 12.8337 7.00008C12.8337 3.77842 10.222 1.16675 7.00033 1.16675C3.77866 1.16675 1.16699 3.77842 1.16699 7.00008C1.16699 10.2217 3.77866 12.8334 7.00033 12.8334Z M1.16699 7H12.8337 M7.00033 1.16675C8.45941 2.76412 9.2886 4.8371 9.33366 7.00008C9.2886 9.16306 8.45941 11.236 7.00033 12.8334C5.54125 11.236 4.71205 9.16306 4.66699 7.00008C4.71205 4.8371 5.54125 2.76412 7.00033 1.16675V1.16675Z',
    ],
    customStyles: {
        fill: 'none',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        'stroke-width': '0.7',
        stroke: 'currentColor',
    },
};

export const facClock: CustomIcon = {
    prefix: 'fac',
    iconName: 'clock',
    icon: [
        10,
        10,
        [],
        '',
        'M4.48477 0.748793V0C3.50764 0.095255 2.57561 0.471262 1.81395 1.10295L2.34073 1.64843C2.94441 1.16825 3.68022 0.847232 4.48477 0.748793ZM2.32889 8.37015C2.93477 8.85556 3.67495 9.18015 4.48477 9.27924V9.99676C3.50263 9.89148 2.5706 9.49542 1.81395 8.86373L2.32889 8.37015ZM0.741934 5.48468C0.830125 6.29464 1.14328 7.03699 1.61757 7.64788L1.11242 8.15683C0.49107 7.39479 0.105229 6.46731 0.00501091 5.48468H0.741934ZM1.64389 2.34659C1.16702 2.94843 0.847904 3.68108 0.74906 4.482H0C0.100218 3.49937 0.476037 2.57188 1.10741 1.80984L1.64389 2.34659ZM5.4882 0.0100502V0C7.85915 0.236181 9.74889 2.1206 9.97445 4.50754C10.2501 7.27136 8.24512 9.72362 5.4882 10V9.30315C7.63571 9.06516 9.30604 7.23993 9.30604 5.0236C9.30604 2.80727 7.63571 0.982044 5.4882 0.744053V0.0100502ZM5.2382 5.13551V2.49731H4.48632V5.51239L7.11792 7.0953L7.49386 6.47721L5.2382 5.13551Z',
    ],
};

export const facExternalLink: CustomIcon = {
    prefix: 'fac',
    iconName: 'facExternalLink',
    icon: [
        8,
        8,
        [],
        '',
        'M1.47481 1C1.48323 1 1.49166 1 1.50012 1H2.50012C2.77627 1 3.00012 1.22386 3.00012 1.5C3.00012 1.77615 2.77627 2 2.50012 2H1.50012C1.26332 2 1.11455 2.00061 1.00956 2.00944C1.00073 2.11443 1.00012 2.2632 1.00012 2.5L1 6.49975C1.00025 6.73668 1.00097 6.88515 1.00999 6.99003C1.11445 6.99913 1.26312 7 1.5 7H5.5C5.7368 7 5.88557 6.9994 5.99057 6.99057C5.99939 6.88557 6 6.73681 6 6.5V5.5C6 5.22386 6.22386 5 6.5 5C6.77614 5 7 5.22386 7 5.5L7 6.52532C7.00003 6.75344 7.00005 6.96474 6.98132 7.13334C6.9623 7.30448 6.91497 7.54214 6.72855 7.72856C6.54214 7.91497 6.30448 7.96231 6.13334 7.98132C5.96474 8.00005 5.75343 8.00003 5.52532 8.00001L1.48461 8C1.25216 8.00001 1.03704 8.00002 0.865435 7.98047C0.693391 7.96088 0.456425 7.9124 0.270792 7.72566C0.0855642 7.53932 0.0382495 7.30249 0.0191626 7.13153C0.000437021 6.96382 0.000239581 6.75394 2.69413e-05 6.52788C1.8388e-05 6.51879 9.80496e-06 6.50968 0 6.50054L0.000123084 2.5C0.000123084 2.5 0.000123084 2.50001 0.000123084 2.5C0.000123084 2.49155 0.00012216 2.4831 0.000121236 2.47469C9.58443e-05 2.24657 7.23302e-05 2.03527 0.0188062 1.86666C0.0378217 1.69552 0.0851565 1.45786 0.27157 1.27145C0.457983 1.08504 0.695644 1.0377 0.866782 1.01869C1.03539 0.999953 1.24669 0.999976 1.47481 1Z M7.49986 4C7.22371 3.99999 6.99987 3.77612 6.99988 3.49998L6.99998 1L4.5 1C4.22386 1 4 0.776142 4 0.5C4 0.223857 4.22386 -3.30121e-07 4.5 -3.0598e-07L7.5 -4.37114e-08C7.63261 -3.21181e-08 7.75979 0.0526812 7.85356 0.146454C7.94733 0.240227 8.00001 0.367409 8 0.500021L7.99988 3.50002C7.99987 3.77616 7.776 4.00001 7.49986 4Z M3.14645 4.85355C2.95118 4.65829 2.95118 4.34171 3.14645 4.14645L7.14645 0.146441C7.34171 -0.0488206 7.65829 -0.048821 7.85355 0.146441C8.04882 0.341703 8.04882 0.658286 7.85355 0.853548L3.85355 4.85355C3.65829 5.04882 3.34171 5.04882 3.14645 4.85355Z',
    ],
};

export const facResume =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.265 2.04445V1C5.9 1.133 4.598 1.658 3.534 2.54L4.26899 3.30069C5.11252 2.63007 6.14073 2.18178 7.265 2.04445ZM4.25348 12.6867C5.0999 13.3642 6.13383 13.8172 7.265 13.9553V14.958C5.893 14.811 4.591 14.258 3.534 13.376L4.25348 12.6867ZM2.03568 8.658C2.15907 9.78899 2.59677 10.8255 3.25958 11.6784L2.554 12.389C1.686 11.325 1.147 10.03 1.007 8.658H2.03568ZM3.29553 4.27553C2.6292 5.11608 2.18336 6.13937 2.04541 7.258H1C1.14 5.886 1.665 4.591 2.547 3.527L3.29553 4.27553Z" fill="#327DE2"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.66797 1.014V1C11.9801 1.32911 14.6199 3.95498 14.935 7.28107C15.3202 11.1323 12.5192 14.5495 8.66797 14.9346V13.9633C11.6675 13.6313 14.0005 11.0881 14.0005 8C14.0005 4.91191 11.6675 2.36871 8.66797 2.03671V1.014Z" fill="#327DE2"/>' +
    '<path d="M6.5 11L10.5 8L6.5 5V11Z" fill="#327DE2"/>' +
    '</svg>';

export const facCollapsed =
    '<svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<g filter="url(#filter0_d_430_120258)" transform="translate(-8,-4)">\n' +
    '<path d="M8 4H20C22.2091 4 24 5.79086 24 8V32C24 34.2091 22.2091 36 20 36H8V4Z" fill="#0097AB"/>\n' +
    '</g>\n' +
    '<path d="M16.5 22.5L19 20L16.5 17.5M13 22.5L15.5 20L13 17.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" transform="translate(-8,-4)"/>\n' +
    '<defs>\n' +
    '<filter id="filter0_d_430_120258" x="0" y="0" width="32" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
    '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
    '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>\n' +
    '<feOffset dy="4"/>\n' +
    '<feGaussianBlur stdDeviation="4"/>\n' +
    '<feColorMatrix type="matrix" values="0 0 0 0 0.0588235 0 0 0 0 0.14902 0 0 0 0 0.266667 0 0 0 0.16 0"/>\n' +
    '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_430_120258"/>\n' +
    '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_430_120258" result="shape"/>\n' +
    '</filter>\n' +
    '</defs>\n' +
    '</svg>\n';

export const facExpanded =
    '<svg width="16" height="32" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<g filter="url(#filter0_d_430_120256)" transform="translate(-8,-4)">\n' +
    '<path d="M8 4H20C22.2091 4 24 5.79086 24 8V32C24 34.2091 22.2091 36 20 36H8V4Z" fill="#0097AB"/>\n' +
    '</g>\n' +
    '<path d="M15.5 22.5L13 20L15.5 17.5M19 22.5L16.5 20L19 17.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" transform="translate(-8,-4)"/>\n' +
    '<defs>\n' +
    '<filter id="filter0_d_430_120256" x="0" y="0" width="32" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
    '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
    '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>\n' +
    '<feOffset dy="4"/>\n' +
    '<feGaussianBlur stdDeviation="4"/>\n' +
    '<feColorMatrix type="matrix" values="0 0 0 0 0.0588235 0 0 0 0 0.14902 0 0 0 0 0.266667 0 0 0 0.16 0"/>\n' +
    '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_430_120256"/>\n' +
    '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_430_120256" result="shape"/>\n' +
    '</filter>\n' +
    '</defs>\n' +
    '</svg>\n';

export const facExclamationTriangleThin =
    '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M34.2991 12.8666L6.06576 60C5.48365 61.008 5.17565 62.151 5.17239 63.315C5.16913 64.4791 5.47073 65.6237 6.04718 66.6351C6.62363 67.6464 7.45484 68.4891 8.45812 69.0795C9.46139 69.6698 10.6018 69.9872 11.7658 70H68.2324C69.3964 69.9872 70.5368 69.6698 71.5401 69.0795C72.5433 68.4891 73.3746 67.6464 73.951 66.6351C74.5275 65.6237 74.8291 64.4791 74.8258 63.315C74.8225 62.151 74.5145 61.008 73.9324 60L45.6991 12.8666C45.1049 11.887 44.2682 11.077 43.2697 10.5149C42.2713 9.95279 41.1449 9.65749 39.9991 9.65749C38.8533 9.65749 37.7269 9.95279 36.7284 10.5149C35.73 11.077 34.8933 11.887 34.2991 12.8666V12.8666Z" stroke="#FF9800" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M40 30V43.3333" stroke="#FF9800" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M40.0002 56.6667C40.9206 56.6667 41.6668 55.9205 41.6668 55C41.6668 54.0795 40.9206 53.3333 40.0002 53.3333C39.0797 53.3333 38.3335 54.0795 38.3335 55C38.3335 55.9205 39.0797 56.6667 40.0002 56.6667Z" stroke="#FF9800" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</svg>\n';

export const facSub =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<rect x="12.5" y="7.5" width="3.5" height="1" fill="#4CB6C4"/>\n' +
    '<rect x="7.5" width="1" height="3.5" fill="#4CB6C4"/>\n' +
    '<path d="M8 2V4C8 6.20914 9.79086 8 12 8H14" stroke="#4CB6C4"/>\n' +
    '</svg>';

export const facEyeOpen: CustomIcon = {
    prefix: 'fac',
    iconName: 'facEyeOpen',
    icon: [
        14,
        14,
        [],
        '',
        'M1.31172 8.44401C1.15586 8.16884 1.15586 7.83207 1.31172 7.5569C1.38702 7.42396 1.49455 7.24215 1.6334 7.02715L2.38944 7.51542C2.26106 7.7142 2.16252 7.88094 2.09482 8.00045C2.16252 8.11997 2.26106 8.28671 2.38944 8.48549L1.6334 8.97376C1.49455 8.75876 1.38702 8.57695 1.31172 8.44401ZM3.58841 10.026C3.06454 9.46722 2.66148 8.90671 2.38944 8.48549L1.6334 8.97376C1.92646 9.42753 2.36194 10.0337 2.93183 10.6416L3.58841 10.026ZM13.9046 8.00045C13.8369 8.11996 13.7383 8.28671 13.61 8.48549C13.3379 8.90671 12.9349 9.46722 12.411 10.026C11.3551 11.1524 9.86159 12.2171 7.9997 12.2171C6.13781 12.2171 4.64435 11.1524 3.58841 10.026L2.93183 10.6416C4.06366 11.8488 5.77851 13.1171 7.9997 13.1171C10.2209 13.1171 11.9357 11.8488 13.0676 10.6416C13.6375 10.0337 14.0729 9.42753 14.366 8.97376C14.5049 8.75875 14.6124 8.57695 14.6877 8.44401C14.8435 8.16884 14.8435 7.83206 14.6877 7.5569C14.6124 7.42395 14.5049 7.24215 14.366 7.02715C14.0729 6.57338 13.6375 5.96723 13.0676 5.35935L12.411 5.9749L13.0676 5.35935C11.9357 4.15207 10.2209 2.88379 7.9997 2.88379C5.77851 2.88379 4.06366 4.15206 2.93183 5.35935C2.36194 5.96723 1.92646 6.57338 1.6334 7.02715L2.38944 7.51542C2.66148 7.0942 3.06454 6.53369 3.58841 5.9749C4.64435 4.84856 6.13781 3.78379 7.9997 3.78379C9.86159 3.78379 11.3551 4.84856 12.411 5.9749C12.9349 6.53369 13.3379 7.0942 13.61 7.51542C13.7383 7.7142 13.8369 7.88094 13.9046 8.00045Z M7.9998 6.6998C7.28183 6.6998 6.6998 7.28183 6.6998 7.9998C6.6998 8.71777 7.28183 9.2998 7.9998 9.2998C8.71777 9.2998 9.2998 8.71777 9.2998 7.9998C9.2998 7.28183 8.71777 6.6998 7.9998 6.6998ZM5.7998 7.9998C5.7998 6.78478 6.78478 5.7998 7.9998 5.7998C9.21483 5.7998 10.1998 6.78478 10.1998 7.9998C10.1998 9.21483 9.21483 10.1998 7.9998 10.1998C6.78478 10.1998 5.7998 9.21483 5.7998 7.9998Z',
    ],
};

export const facShape =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M5.33341 5.33317H10.6667M5.33341 7.99984H8.66675M2.66675 2.6665H13.3334V10.6665H8.00008L4.66675 13.9998V10.6665H2.66675V2.6665Z" stroke="#BDBCBC" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>';

export const warning =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M8 9C7.86739 9 7.74021 8.94267 7.64645 8.84061C7.55268 8.73856 7.5 8.60014 7.5 8.45581V5.54419C7.5 5.39986 7.55268 5.26144 7.64645 5.15939C7.74021 5.05733 7.86739 5 8 5C8.13261 5 8.25979 5.05733 8.35355 5.15939C8.44732 5.26144 8.5 5.39986 8.5 5.54419V8.45581C8.5 8.60014 8.44732 8.73856 8.35355 8.84061C8.25979 8.94267 8.13261 9 8 9Z" fill="#FF9800"/>\n' +
    '<path d="M8 11C8.27614 11 8.5 10.7761 8.5 10.5C8.5 10.2239 8.27614 10 8 10C7.72386 10 7.5 10.2239 7.5 10.5C7.5 10.7761 7.72386 11 8 11Z" fill="#FF9800"/>\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.77249 1.69137L0.692395 11.8456C0.567037 12.0628 0.500708 12.309 0.500006 12.5598C0.499304 12.8106 0.564254 13.0572 0.688394 13.2751C0.812534 13.4929 0.991537 13.6745 1.20759 13.8017C1.42365 13.9289 1.66923 13.9972 1.9199 14H14.0801C14.3308 13.9972 14.5763 13.9289 14.7924 13.8017C15.0085 13.6745 15.1875 13.4929 15.3116 13.2751C15.4357 13.0572 15.5007 12.8106 15.5 12.5598C15.4993 12.309 15.433 12.0628 15.3076 11.8456L9.22751 1.69137C9.09954 1.48032 8.91935 1.30582 8.70434 1.18472C8.48933 1.06362 8.24675 1 8 1C7.75325 1 7.51067 1.06362 7.29566 1.18472C7.08065 1.30582 6.90046 1.48032 6.77249 1.69137ZM14.4443 12.3505L8.37138 2.20815C8.33257 2.14479 8.27825 2.09244 8.2136 2.05602C8.14837 2.01929 8.0748 2 8 2C7.9252 2 7.85163 2.01929 7.7864 2.05602C7.72174 2.09245 7.66741 2.14481 7.6286 2.20818L1.55566 12.3505C1.51939 12.4153 1.50021 12.4883 1.5 12.5626C1.49979 12.6389 1.51954 12.7138 1.55726 12.78C1.59497 12.8462 1.64933 12.9013 1.71488 12.9399C1.77931 12.9778 1.85237 12.9985 1.92701 13H14.073C14.1476 12.9985 14.2207 12.9778 14.2851 12.9399C14.3507 12.9013 14.405 12.8462 14.4427 12.78C14.4805 12.7138 14.5002 12.6389 14.5 12.5626C14.4998 12.4883 14.4806 12.4153 14.4443 12.3505Z" fill="#FF9800"/>\n' +
    '</svg>';

export const facEditorExecution =
    '<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M8.05 0C8.4366 0 8.75 0.313401 8.75 0.7V2.8C8.75 3.1866 8.4366 3.5 8.05 3.5H5.95C5.5634 3.5 5.25 3.1866 5.25 2.8V0.7C5.25 0.313401 5.5634 0 5.95 0H8.05ZM5.95 0.7V2.8H8.05V0.7H5.95Z" fill="#747474"/>' +
    '<path d="M8.05 8C8.4366 8 8.75 8.3134 8.75 8.7V10.8C8.75 11.1866 8.4366 11.5 8.05 11.5H5.95C5.5634 11.5 5.25 11.1866 5.25 10.8V8.7C5.25 8.3134 5.5634 8 5.95 8H8.05ZM5.95 8.7V10.8H8.05V8.7H5.95Z" fill="#747474"/>' +
    '<path d="M2.8 8C3.1866 8 3.5 8.3134 3.5 8.7V10.8C3.5 11.1866 3.1866 11.5 2.8 11.5H0.7C0.313401 11.5 0 11.1866 0 10.8V8.7C0 8.3134 0.313401 8 0.7 8H2.8ZM0.7 8.7V10.8H2.8V8.7H0.7Z" fill="#747474"/>' +
    '<path d="M7.25 3.25L7.2498 5.749L12.7 5.75C12.7 5.75 12.7 5.83579 12.7 6.25L7.2498 6.249L7.25 8.25H6.75L6.7498 6.249L2.2998 6.25C2.16173 6.25 2.0498 6.36193 2.0498 6.5V8.2H1.5498V6.5C1.5498 6.08579 1.88559 5.75 2.2998 5.75L6.7498 5.749L6.75 3.25H7.25Z" fill="#747474"/>' +
    '<path d="M14.7586 6.98754L14.7585 6.98757L14.7625 6.99144C15.3236 7.54492 15.6 8.20663 15.6 9C15.6 9.7935 15.3235 10.4607 14.7605 11.0236C14.2075 11.5767 13.5452 11.85 12.75 11.85C11.9556 11.85 11.2885 11.5771 10.7264 11.0236C10.1729 10.4615 9.9 9.79434 9.9 9C9.9 8.20484 10.1733 7.54255 10.7264 6.9895C11.2893 6.42655 11.9565 6.15 12.75 6.15C13.5434 6.15 14.2051 6.42637 14.7586 6.98754Z" stroke="#327DE2" stroke-width="0.8"/>' +
    '<path d="M13.5052 10.3037L12.1777 9.31514C12.1256 9.27263 12.0996 9.21948 12.0996 9.15571V7.54093C12.0996 7.41338 12.1621 7.34961 12.287 7.34961H12.7868C12.9117 7.34961 12.9742 7.41338 12.9742 7.54093V8.80494L14.0205 9.57024C14.1143 9.64465 14.1247 9.73499 14.0518 9.84129L13.7707 10.2558C13.6978 10.3621 13.6093 10.3781 13.5052 10.3037Z" fill="#327DE2"/>' +
    '</svg>';

export const facPortfolioInPortfolio =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M13.3335 4.66675H2.66683C1.93045 4.66675 1.3335 5.2637 1.3335 6.00008V12.6667C1.3335 13.4031 1.93045 14.0001 2.66683 14.0001H13.3335C14.0699 14.0001 14.6668 13.4031 14.6668 12.6667V6.00008C14.6668 5.2637 14.0699 4.66675 13.3335 4.66675Z" stroke="#747474" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M10.6668 14V3.33333C10.6668 2.97971 10.5264 2.64057 10.2763 2.39052C10.0263 2.14048 9.68712 2 9.3335 2H6.66683C6.31321 2 5.97407 2.14048 5.72402 2.39052C5.47397 2.64057 5.3335 2.97971 5.3335 3.33333V14" stroke="#747474" stroke-linecap="round" stroke-linejoin="round"/>' +
    '</svg>';

export const facSubstract =
    '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4766 6.49906C11.3736 7.64242 10.9207 8.68488 10.2257 9.51855L9.51505 8.80786C10.0329 8.16049 10.3757 7.36697 10.4716 6.49906H11.4766ZM8.80795 9.51498L9.51863 10.2257C8.68495 10.9206 7.64246 11.3736 6.49906 11.4766V10.4716C7.36701 10.3757 8.16056 10.0329 8.80795 9.51498ZM9.51863 1.77246C8.68495 1.07748 7.64246 0.624521 6.49906 0.521484V1.52653C7.36701 1.62247 8.16056 1.96527 8.80795 2.48315L9.51863 1.77246ZM10.2257 2.47958L9.51505 3.19026C10.0329 3.83764 10.3757 4.63116 10.4716 5.49906L11.4766 5.49906C11.3736 4.35571 10.9207 3.31324 10.2257 2.47958ZM1.52653 5.49906C1.62247 4.63112 1.96527 3.83757 2.48315 3.19018L1.77246 2.47949C1.07748 3.31317 0.624521 4.35567 0.521484 5.49906H1.52653ZM2.47958 1.77239L3.19026 2.48308C3.83764 1.96524 4.63116 1.62247 5.49906 1.52653V0.521484C4.3557 0.624518 3.31324 1.07745 2.47958 1.77239ZM1.52653 6.49906H0.521485C0.624522 7.64246 1.07749 8.68495 1.77246 9.51863L2.48315 8.80795C1.96527 8.16056 1.62247 7.36701 1.52653 6.49906ZM3.19026 9.51505L2.47958 10.2257C3.31324 10.9207 4.35571 11.3736 5.49906 11.4766V10.4716C4.63116 10.3757 3.83764 10.0329 3.19026 9.51505Z" fill="#0097AB"/>' +
    '</svg>';

export const boldBell: CustomIcon = {
    prefix: 'fac',
    iconName: 'boldBell',
    icon: [
        14,
        14,
        [],
        '',
        'M8.24976 14C9.07757 14 9.74905 13.3285 9.74905 12.5H6.75047C6.75047 13.3285 7.42195 14 8.24976 14ZM13.2979 10.4912C12.8451 10.0046 11.9979 9.27266 11.9979 6.875C11.9979 5.05391 10.721 3.59609 8.99929 3.23844V2.75C8.99929 2.33586 8.66366 2 8.24976 2C7.83585 2 7.50023 2.33586 7.50023 2.75V3.23844C5.77852 3.59609 4.50164 5.05391 4.50164 6.875C4.50164 9.27266 3.65438 10.0046 3.20157 10.4912C3.06094 10.6423 2.9986 10.823 2.99977 11C3.00235 11.3844 3.30399 11.75 3.75211 11.75H12.7474C13.1955 11.75 13.4974 11.3844 13.4997 11C13.5009 10.823 13.4386 10.6421 13.2979 10.4912Z',
    ],
};

export const ellipsis: CustomIcon = {
    prefix: 'fac',
    iconName: 'ellipsis',
    icon: [
        14,
        14,
        [],
        '',
        'M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z',
    ],
};

export const facApproveCheckMark =
    '<svg width="22" height="12" viewBox="0 0 10 14" stroke="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path\n' +
    'd="M5.14063 9.73173L1.5497 5.97065L0 7.58995L3.63422 11.3391C3.83374 11.5485 4.07094 11.7148 4.33219 11.8282C4.59345 11.9416 4.8736 12 5.15655 12C5.43949 12 5.71964 11.9416 5.9809 11.8282C6.24215 11.7148 6.47936 11.5485 6.67887 11.3391L16 1.60733L14.4516 0L5.14063 9.73173Z"\n' +
    'fill="#327DE2"\n' +
    '/>\n' +
    '</svg>';

export const facMenuEdit =
    '<svg width="16" height="16" viewBox="0 0 16 16">\n' +
    '            <path\n' +
    '                d="M7.98336 8.25065C8.30552 8.25065 8.56669 7.98948 8.56669 7.66732C8.56669 7.34515 8.30552 7.08398 7.98336 7.08398C7.66119 7.08398 7.40002 7.34515 7.40002 7.66732C7.40002 7.98948 7.66119 8.25065 7.98336 8.25065Z"\n' +
    '                stroke="#747474"\n' +
    '                stroke-linecap="round"\n' +
    '                stroke-linejoin="round"\n' +
    '            />\n' +
    '            <path\n' +
    '                d="M7.98336 4.16667C8.30552 4.16667 8.56669 3.9055 8.56669 3.58333C8.56669 3.26117 8.30552 3 7.98336 3C7.66119 3 7.40002 3.26117 7.40002 3.58333C7.40002 3.9055 7.66119 4.16667 7.98336 4.16667Z"\n' +
    '                stroke="#747474"\n' +
    '                stroke-linecap="round"\n' +
    '                stroke-linejoin="round"\n' +
    '            />\n' +
    '            <path\n' +
    '                d="M7.98336 12.3337C8.30552 12.3337 8.56669 12.0725 8.56669 11.7503C8.56669 11.4282 8.30552 11.167 7.98336 11.167C7.66119 11.167 7.40002 11.4282 7.40002 11.7503C7.40002 12.0725 7.66119 12.3337 7.98336 12.3337Z"\n' +
    '                stroke="#747474"\n' +
    '                stroke-linecap="round"\n' +
    '                stroke-linejoin="round"\n' +
    '            />\n' +
    '        </svg>';

export const facCalendar: CustomIcon = {
    prefix: 'fac',
    iconName: 'ellipsis',
    icon: [
        16,
        16,
        [],
        '',
        'M5.92188 8.625H4.82812C4.60938 8.625 4.5 8.51562 4.5 8.29688V7.20312C4.5 6.98438 4.60938 6.875 4.82812 6.875H5.92188C6.14062 6.875 6.25 6.98438 6.25 7.20312V8.29688C6.25 8.51562 6.14062 8.625 5.92188 8.625ZM8.875 8.29688C8.875 8.51562 8.76562 8.625 8.54688 8.625H7.45312C7.23438 8.625 7.125 8.51562 7.125 8.29688V7.20312C7.125 6.98438 7.23438 6.875 7.45312 6.875H8.54688C8.76562 6.875 8.875 6.98438 8.875 7.20312V8.29688ZM11.5 8.29688C11.5 8.51562 11.3906 8.625 11.1719 8.625H10.0781C9.85938 8.625 9.75 8.51562 9.75 8.29688V7.20312C9.75 6.98438 9.85938 6.875 10.0781 6.875H11.1719C11.3906 6.875 11.5 6.98438 11.5 7.20312V8.29688ZM8.875 10.9219C8.875 11.1406 8.76562 11.25 8.54688 11.25H7.45312C7.23438 11.25 7.125 11.1406 7.125 10.9219V9.82812C7.125 9.60938 7.23438 9.5 7.45312 9.5H8.54688C8.76562 9.5 8.875 9.60938 8.875 9.82812V10.9219ZM6.25 10.9219C6.25 11.1406 6.14062 11.25 5.92188 11.25H4.82812C4.60938 11.25 4.5 11.1406 4.5 10.9219V9.82812C4.5 9.60938 4.60938 9.5 4.82812 9.5H5.92188C6.14062 9.5 6.25 9.60938 6.25 9.82812V10.9219ZM11.5 10.9219C11.5 11.1406 11.3906 11.25 11.1719 11.25H10.0781C9.85938 11.25 9.75 11.1406 9.75 10.9219V9.82812C9.75 9.60938 9.85938 9.5 10.0781 9.5H11.1719C11.3906 9.5 11.5 9.60938 11.5 9.82812V10.9219ZM14.125 3.8125V13.4375C14.125 13.8021 13.9974 14.112 13.7422 14.3672C13.487 14.6224 13.1771 14.75 12.8125 14.75H3.1875C2.82292 14.75 2.51302 14.6224 2.25781 14.3672C2.0026 14.112 1.875 13.8021 1.875 13.4375V3.8125C1.875 3.44792 2.0026 3.13802 2.25781 2.88281C2.51302 2.6276 2.82292 2.5 3.1875 2.5H4.5V1.07812C4.5 0.859375 4.60938 0.75 4.82812 0.75H5.92188C6.14062 0.75 6.25 0.859375 6.25 1.07812V2.5H9.75V1.07812C9.75 0.859375 9.85938 0.75 10.0781 0.75H11.1719C11.3906 0.75 11.5 0.859375 11.5 1.07812V2.5H12.8125C13.1771 2.5 13.487 2.6276 13.7422 2.88281C13.9974 3.13802 14.125 3.44792 14.125 3.8125ZM12.8125 13.2734V5.125H3.1875V13.2734C3.1875 13.3828 3.24219 13.4375 3.35156 13.4375H12.6484C12.7578 13.4375 12.8125 13.3828 12.8125 13.2734Z',
    ],
};

export const facExportWidgetData: CustomIcon = {
    prefix: 'fac',
    iconName: 'exportWidgetData',
    icon: [
        24,
        24,
        [],
        '',
        'M2.5 8.5L2.5 19C2.5 19.3978 2.65804 19.7794 2.93934 20.0607C3.22065 20.342 3.60218 20.5 4 20.5H14C14.2761 20.5 14.5 20.7239 14.5 21C14.5 21.2761 14.2761 21.5 14 21.5H4C3.33696 21.5 2.70107 21.2366 2.23223 20.7678C1.76339 20.2989 1.5 19.663 1.5 19V8V5C1.5 4.33696 1.76339 3.70107 2.23223 3.23223C2.70107 2.76339 3.33696 2.5 4 2.5H20C20.663 2.5 21.2989 2.76339 21.7678 3.23223C22.2366 3.70107 22.5 4.33696 22.5 5V8V11C22.5 11.2761 22.2761 11.5 22 11.5C21.7239 11.5 21.5 11.2761 21.5 11V8.5H2.5ZM2.5 7.5H21.5V5C21.5 4.60217 21.342 4.22065 21.0607 3.93934C20.7794 3.65803 20.3978 3.5 20 3.5L4 3.5C3.60218 3.5 3.22064 3.65804 2.93934 3.93934C2.65804 4.22064 2.5 4.60217 2.5 5L2.5 7.5ZM23.4621 16.8086C23.4377 16.7496 23.4015 16.6944 23.3536 16.6464L23.3535 16.6464L19.3536 12.6464C19.1583 12.4512 18.8417 12.4512 18.6464 12.6464C18.4512 12.8417 18.4512 13.1583 18.6464 13.3536L21.7929 16.5H13C12.7239 16.5 12.5 16.7239 12.5 17C12.5 17.2761 12.7239 17.5 13 17.5H21.7929L18.6464 20.6464C18.4512 20.8417 18.4512 21.1583 18.6464 21.3536C18.8417 21.5488 19.1583 21.5488 19.3536 21.3536L23.3536 17.3536C23.4015 17.3056 23.4377 17.2504 23.4621 17.1914C23.4842 17.1378 23.4967 17.0811 23.4994 17.024C23.4998 17.016 23.5 17.008 23.5 17C23.5 16.9322 23.4865 16.8676 23.4621 16.8086Z',
    ],
    customStyles: {
        stroke: 'currentColor',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        fill: 'none',
    },
};

export const resizeLineExpand: CustomIcon = {
    prefix: 'fac',
    iconName: 'resizeLineExpand',
    icon: [
        16,
        16,
        [],
        '',
        'M8.39286 2.5C8.28866 2.5 8.18874 2.54139 8.11506 2.61507C8.04139 2.68874 8 2.78867 8 2.89286C8 2.99705 8.04139 3.09698 8.11506 3.17066C8.18874 3.24433 8.28866 3.28572 8.39286 3.28572H12.1604L8.52643 6.91969C8.4853 6.95491 8.4519 6.99825 8.42832 7.04699C8.40474 7.09573 8.39149 7.14881 8.3894 7.20292C8.38731 7.25702 8.39643 7.31098 8.41618 7.36139C8.43593 7.4118 8.46589 7.45759 8.50417 7.49588C8.54246 7.53416 8.58825 7.56412 8.63866 7.58387C8.68907 7.60362 8.74302 7.61274 8.79713 7.61065C8.85123 7.60856 8.90432 7.59531 8.95306 7.57173C9.0018 7.54815 9.04514 7.51475 9.08036 7.47362L12.7143 3.83966V7.60719C12.7143 7.71139 12.7557 7.81131 12.8294 7.88499C12.903 7.95866 13.003 8.00005 13.1071 8.00005C13.2113 8.00005 13.3113 7.95866 13.3849 7.88499C13.4586 7.81131 13.5 7.71139 13.5 7.60719V2.5H8.39286Z M2.5 8.39286C2.5 8.28867 2.54139 8.18874 2.61507 8.11507C2.68874 8.04139 2.78866 8 2.89286 8C2.99705 8 3.09697 8.04139 3.17065 8.11507C3.24432 8.18874 3.28571 8.28867 3.28571 8.39286V12.1604L6.91964 8.52643C6.95486 8.48531 6.9982 8.45191 7.04694 8.42833C7.09568 8.40475 7.14877 8.39149 7.20287 8.3894C7.25698 8.38731 7.31093 8.39643 7.36134 8.41618C7.41176 8.43593 7.45754 8.46589 7.49583 8.50418C7.53411 8.54246 7.56407 8.58825 7.58382 8.63867C7.60357 8.68908 7.61269 8.74303 7.6106 8.79714C7.60851 8.85124 7.59526 8.90433 7.57168 8.95307C7.5481 9.00181 7.5147 9.04515 7.47357 9.08037L3.83964 12.7143H7.60714C7.71134 12.7143 7.81126 12.7557 7.88494 12.8294C7.95861 12.9031 8 13.003 8 13.1072C8 13.2114 7.95861 13.3113 7.88494 13.385C7.81126 13.4587 7.71134 13.5001 7.60714 13.5001H2.5V8.39286Z',
    ],
};

export const resizeLineCollapse: CustomIcon = {
    prefix: 'fac',
    iconName: 'resizeLineCollapse',
    icon: [
        16,
        16,
        [],
        '',
        'M1.89286 9C1.78866 9 1.68874 9.04139 1.61506 9.11507C1.54139 9.18874 1.5 9.28867 1.5 9.39286C1.5 9.49705 1.54139 9.59698 1.61506 9.67066C1.68874 9.74433 1.78866 9.78572 1.89286 9.78572H5.66036L2.02643 13.4197C1.9853 13.4549 1.9519 13.4982 1.92832 13.547C1.90474 13.5957 1.89149 13.6488 1.8894 13.7029C1.88731 13.757 1.89643 13.811 1.91618 13.8614C1.93593 13.9118 1.96589 13.9576 2.00417 13.9959C2.04246 14.0342 2.08825 14.0641 2.13866 14.0839C2.18907 14.1036 2.24302 14.1127 2.29713 14.1107C2.35123 14.1086 2.40432 14.0953 2.45306 14.0717C2.5018 14.0481 2.54514 14.0147 2.58036 13.9736L6.21429 10.3397V14.1072C6.21429 14.2114 6.25568 14.3113 6.32935 14.385C6.40303 14.4587 6.50295 14.5001 6.60714 14.5001C6.71134 14.5001 6.81126 14.4587 6.88494 14.385C6.95861 14.3113 7 14.2114 7 14.1072V9H1.89286Z M9 1.89286C9 1.78867 9.04139 1.68874 9.11507 1.61507C9.18874 1.54139 9.28866 1.5 9.39286 1.5C9.49705 1.5 9.59697 1.54139 9.67065 1.61507C9.74432 1.68874 9.78571 1.78867 9.78571 1.89286V5.6604L13.4196 2.02643C13.4549 1.98531 13.4982 1.95191 13.5469 1.92833C13.5957 1.90475 13.6488 1.89149 13.7029 1.8894C13.757 1.88731 13.8109 1.89643 13.8613 1.91618C13.9118 1.93593 13.9575 1.96589 13.9958 2.00418C14.0341 2.04246 14.0641 2.08825 14.0838 2.13867C14.1036 2.18908 14.1127 2.24303 14.1106 2.29714C14.1085 2.35124 14.0953 2.40433 14.0717 2.45307C14.0481 2.50181 14.0147 2.54515 13.9736 2.58037L10.3396 6.21433H14.1071C14.2113 6.21433 14.3113 6.25572 14.3849 6.3294C14.4586 6.40307 14.5 6.503 14.5 6.60719C14.5 6.71139 14.4586 6.81131 14.3849 6.88499C14.3113 6.95866 14.2113 7.00005 14.1071 7.00005H9V1.89286Z',
    ],
};

export const formatText: CustomIcon = {
    prefix: 'fac',
    iconName: 'formatText',
    icon: [
        24,
        24,
        [],
        '',
        'M19.8892 19.6357L17.7612 13.9777C17.7568 13.9656 17.7523 13.9536 17.7476 13.9416L13.6895 3.15172C13.4293 2.45984 12.7547 2 12 2C11.2453 2 10.5707 2.45984 10.3105 3.15172L6.25241 13.9416C6.24777 13.9536 6.24321 13.9656 6.23885 13.9777L4.11085 19.6357C3.76813 20.547 4.24669 21.557 5.17981 21.8917C6.11297 22.2264 7.14717 21.7591 7.48989 20.8478L9.19185 16.3225H14.8081L16.51 20.8478C16.7776 21.5591 17.4665 22 18.1998 22C18.4059 22 18.6155 21.9652 18.8202 21.8918C19.7533 21.557 20.2319 20.547 19.8892 19.6357Z M12 9L13.5 13H10.5L12 9Z',
    ],
    customStyles: {
        stroke: 'currentColor',
        fill: 'none',
    },
};

export const filters =
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M12.6667 9.33341C13.403 9.33341 14 8.73646 14 8.00008C14 7.2637 13.403 6.66675 12.6667 6.66675M12.6667 9.33341C11.9303 9.33341 11.3333 8.73646 11.3333 8.00008C11.3333 7.2637 11.9303 6.66675 12.6667 6.66675M12.6667 9.33341V13.3334M12.6667 6.66675V2.66675M8 10.6667C7.26362 10.6667 6.66667 11.2637 6.66667 12.0001C6.66667 12.7365 7.26362 13.3334 8 13.3334C8.73638 13.3334 9.33333 12.7365 9.33333 12.0001C9.33333 11.2637 8.73638 10.6667 8 10.6667ZM8 10.6667V2.66675M3.33333 5.33341C4.06971 5.33341 4.66667 4.73646 4.66667 4.00008C4.66667 3.2637 4.06971 2.66675 3.33333 2.66675C2.59695 2.66675 2 3.2637 2 4.00008C2 4.73646 2.59695 5.33341 3.33333 5.33341ZM3.33333 5.33341V13.3334" stroke="#747474" stroke-linecap="round"/>\n' +
    '</svg>';

export const facStarR: CustomIcon = {
    prefix: 'fac',
    iconName: 'starR',
    icon: [24, 24, [], '', 'M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'],
    customStyles: {
        stroke: 'currentColor',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        fill: 'none',
    },
};

export const facStarS: CustomIcon = {
    prefix: 'fac',
    iconName: 'starS',
    icon: [24, 24, [], '', 'M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'],
    customStyles: {
        fill: '#FCE65A',
        stroke: '#FADB14',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
    },
};

export const facFilter: CustomIcon = {
    prefix: 'fac',
    iconName: 'facFilter',
    icon: [16, 14, [], '', 'M14.6667 1H1.33337L6.66671 7.30667V11.6667L9.33337 13V7.30667L14.6667 1Z'],
    customStyles: {
        stroke: 'white',
        fill: 'none',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
    },
};

export const facFilterDark: CustomIcon = {
    prefix: 'fac',
    iconName: 'facFilterDark',
    icon: [24, 24, [], '', 'M22 3H2L10 12.46V19L14 21V12.46L22 3Z'],
    customStyles: {
        fill: 'black',
        stroke: 'currentColor',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
    },
};

export const facTriangleUp: CustomIcon = {
    prefix: 'fac',
    iconName: 'triangleUp',
    icon: [24, 24, [], '', 'M3 19L12 5L21 19L3 19Z'],
    customStyles: {
        fill: 'none',
        stroke: 'currentColor',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
    },
};

export const facThreshold = 
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="stroke: currentColor;">' +
    '<path d="M23 12L1 12" stroke="#FB4E4E" stroke-linecap="round" stroke-linejoin="round"/>' +
    '<path d="M2 8H6L9 3L11.3333 10M22 16H18L15 21L12.6667 14" stroke-linecap="round" stroke-linejoin="round"/>'+
    '</svg>';

export const facTriangleDown: CustomIcon = {
    prefix: 'fac',
    iconName: 'triangleDown',
    icon: [24, 24, [], '', 'M21 5L12 19L3 5L21 5Z'],
    customStyles: {
        fill: 'none',
        stroke: 'currentColor',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
    },
};

export const facFilters: CustomIcon = {
    prefix: 'fac',
    iconName: 'facFilters',
    icon: [
        24,
        24,
        [],
        '',
        'M19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10M19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10M19 14V20M19 10V4M12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16ZM12 16V4M5 8C6.10457 8 7 7.10457 7 6C7 4.89543 6.10457 4 5 4C3.89543 4 3 4.89543 3 6C3 7.10457 3.89543 8 5 8ZM5 8V20',
    ],
    customStyles: {
        fill: 'none',
        stroke: 'currentColor',
        'stroke-linecap': 'round',
    },
};

export const facFilterGroup: CustomIcon = {
    prefix: 'fac',
    iconName: 'facFilterGroup',
    icon: [
        12,
        12,
        [],
        '',
        'M4 6H8M2.5 1.5H9.5C10.0523 1.5 10.5 1.94772 10.5 2.5V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H2.5C1.94772 10.5 1.5 10.0523 1.5 9.5V2.5C1.5 1.94772 1.94772 1.5 2.5 1.5Z',
    ],
    customStyles: {
        fill: 'none',
        stroke: '#4CB6C4',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
    },
};

export const facLine: CustomIcon = {
    prefix: 'fac',
    iconName: 'line',
    icon: [5, 9, [], '', 'M0.739258 1H10.0726'],
    customStyles: {
        fill: 'none',
        stroke: '#0097AB',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
    },
};

export const checkMark: CustomIcon = {
    prefix: 'fac',
    iconName: 'checkmark',
    icon: [9, 9, [], '', 'M11.7389 1L4.4056 8.33333L1.07227 5'],
    customStyles: {
        fill: 'none',
        stroke: '#0097AB',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
    },
};