<div class="d-block mb-4"
     [ngClass]="{ 'main-content-header main-content-header-shadow with-title': title }"
     *transloco="let t; read: 'assets.listWrapper'"
>
    <h1 *ngIf="title">{{ title }}</h1>

    <div class="d-flex align-items-center" [class.mt-24px]="isManageAssets">
        <app-assets-filter
            class="w-100"
            [filter]="filter"
            (filterChange)="filter = $event; filterChanged$.next($event)"
            [isManageAssets]="isManageAssets"
            [(layout)]="layout"
        >
            <div *ngIf="filters.length" class="d-flex flex-wrap align-items-center mt-8px"
                 [style.gap]="'10px 0'"
            >
                <lib-tag
                    *ngFor="let filter of filters.slice(0, filtersExpanded ? filters.length : 12)"
                    class="mr-8px"
                    [tag]="filter.name"
                    removable="true"
                    (remove)="removeFilter(filter)"
                ></lib-tag>
                <button *ngIf="filters.length > 12" class="btn btn-link size-s"
                        (click)="filtersExpanded = !filtersExpanded"
                >
                    <fa-icon icon="{{ filtersExpanded ? 'eyeOff' : 'facEyeOpen' }}" class="font-14px mr-7px"></fa-icon>
                    {{ t(filtersExpanded ? 'showLess' : 'showMore') }}
                </button>
                <button class="btn btn-link size-s ml-8px" (click)="removeAllFilters()">
                    <fa-icon icon="facTrash" class="font-14px mr-7px"></fa-icon>
                    {{ t('clearAll') }}
                </button>
            </div>
        </app-assets-filter>
        <div *ngIf="canAddAsset" class="d-inline-flex min-width-fit-content">
            <div class="button-divider mx-4"></div>
            <button class="btn btn-primary" [routerLink]="'new'">
                <fa-icon icon="plus" class="fa font-16px mr-7px"></fa-icon>
                {{ t('addBtn') }}
            </button>
        </div>
    </div>
</div>

<div *ngIf="isManageAssets" class="mx-4">
    <ng-container *transloco="let t; read: 'assets.statusFilter'">
        <lib-insight-card
            color="primary"
            [title]="t('all')"
            [active]="isStatusFilterAll"
            (click)="changeStatusFilter(null)"
            class="mr-2"
        ></lib-insight-card>
        <lib-insight-card
            color="warning"
            [title]="t('awaitingForApproval')"
            [active]="filter.status === assetStatus.AWAITING_FOR_APPROVAL"
            (click)="changeStatusFilter(assetStatus.AWAITING_FOR_APPROVAL)"
            class="mr-2"
        ></lib-insight-card>
        <lib-insight-card
            color="success"
            [title]="t('awaitingLaunch')"
            [active]="filter.status === assetStatus.AWAITING_LAUNCH"
            (click)="changeStatusFilter(assetStatus.AWAITING_LAUNCH)"
            class="mr-2"
        ></lib-insight-card>
        <lib-insight-card
            color="danger"
            [title]="t('rejected')"
            [active]="filter.status === assetStatus.REJECTED"
            (click)="changeStatusFilter(assetStatus.REJECTED)"
            class="mr-2"
        ></lib-insight-card>
    </ng-container>
</div>
<lib-stream-wrapper [dataStream$]="assets$" class="stream-wrapper">
    <ng-template contentTemplate let-assets="data">
        <ng-container *ngIf="assets?.length > 0; else noData">
            <lib-pagination [metaData]="pagination"
                            (pagerdata)="onPageChange($event)"
                            [translatedText]="paginationTranslatedText"
                            [noDataText]="''"
            ></lib-pagination>
            <app-assets-list
                class="d-block my-2"
                [assets]="assets"
                [class.assets-layout-grid]="layout === 'grid'"
                [hasManagementActions]="hasManagementActions"
                [isManageAssets]="isManageAssets"
                [showStatus]="showStatus"
                [isLoggedIn]="isLoggedIn"
                [isGridView]="layout === 'grid'"
            ></app-assets-list>
            <lib-pagination class="d-block mb-3"
                            [metaData]="pagination"
                            (pagerdata)="onPageChange($event)"
                            [translatedText]="paginationTranslatedText"
                            [noDataText]="''"
            ></lib-pagination>
        </ng-container>
    </ng-template>
</lib-stream-wrapper>


<ng-template #noData>
    <app-no-data-available></app-no-data-available>
</ng-template>
