import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@app/auth';
import { takeUntil } from 'rxjs/operators';
import { KeycloakService } from '@app/core/auth/keycloak.service';
import { lifeBuoyIcon, logInIcon, logOutIcon } from '@app/shared/icons/solutionhub-icons';
import { HeaderService } from '@app/core/components/header/header.service';
import { ThemeService } from '@app/core/services/theme.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    icons = {
        logIn: logInIcon,
        logOut: logOutIcon,
        help: lifeBuoyIcon,
    };

    isDarkTheme: boolean;

    collapsed$ = new Subject<boolean>();
    collapsed: boolean = false;

    @ViewChild('topLeftEl') topLeftEl: ElementRef<HTMLDivElement>;

    private destroyed$ = new Subject();

    constructor(
        private readonly themeService: ThemeService,
        private zone: NgZone,
        public headerService: HeaderService,
        public authService: AuthService,
        private modalService: NgbModal,
        private keycloakService: KeycloakService
    ) {}

    ngOnInit() {
        this.themeService.isDarkTheme$.subscribe(isDarkTheme => (this.isDarkTheme = isDarkTheme));
        this.collapsed$.subscribe(collapsed => (this.collapsed = collapsed));
        this.headerService.menuInitialized$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            setTimeout(() => this.checkCollapseState());
        });
    }

    login() {
        this.keycloakService.login(true).then();
    }

    logout() {
        this.authService.logout();
    }

    register() {
        this.keycloakService.register().then();
    }

    checkCollapseState() {
        const target = this.topLeftEl.nativeElement;
        if (this.collapsed) {
            target.classList.add('force-expand');
        }
        const diff = target.scrollWidth - target.offsetWidth;
        if (this.collapsed) {
            target.classList.remove('force-expand');
        }
        const collapsed = diff > 0;
        if (collapsed !== this.collapsed) {
            this.zone.run(() => {
                this.collapsed$.next(collapsed);
            });
        }
    }

    @HostListener('window:storage', ['$event'])
    private checkLoggedIn(event: Storage) {
        if (event.storageArea != localStorage) {
            return;
        }

        const authenticatedValueFromLS = this.authService.getAuthenticatedValueFromLS();
        if (!authenticatedValueFromLS) {
            return;
        }

        if (authenticatedValueFromLS === 'true' && !this.keycloakService.authenticated$.value) {
            this.keycloakService.login().then();
            return;
        }
        if (authenticatedValueFromLS === 'false' && this.keycloakService.authenticated$.value) {
            this.logout();
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
