import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AUTH_PROVIDERS, SessionExpirationTracker } from '@app/auth';

import { RouterStateHistory } from './services/router-state-history.service';
import { HTTP_INTERCEPTOR_PROVIDERS } from './http-interceptors';
import { SharedModule } from '@app/shared/shared.module';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

@NgModule({
    imports: [
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        SharedModule,
    ],
    providers: [RouterStateHistory, ...HTTP_INTERCEPTOR_PROVIDERS, ...AUTH_PROVIDERS],
    declarations: [ProgressBarComponent],
    exports: [ProgressBarComponent],
})
export class CoreModule {
    // Inject RouterStateHistory to make it eager...
    constructor(private routerStateHistory: RouterStateHistory, private sessionExpiration: SessionExpirationTracker) {}
}
