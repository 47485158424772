import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toaster: ToastrService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                this.parseError(err);
                return observableThrowError(err);
            })
        );
    }

    private parseError(err: HttpErrorResponse): void {
        if (err.message) {
            this.handleSpringError(err);
        } else if (err.error.error) {
            this.handleBaseHttpServiceError(err);
        } else {
            this.toaster.error('Handling unknown error format');
        }
    }

    private handleSpringError(err: HttpErrorResponse): void {
        const error = err.error;
        switch (err.status) {
            case 401:
            case 403:
            case 404:
                if (error) {
                    this.toaster.error(error.message, error.error);
                    break;
                }

                this.toaster.error(err.message, err.status.toString());
                break;
            case 400:
                this.parse400Exception(err);
                break;

            case 500:
                this.parse500Exception(err);
                break;

            case 503:
                this.toaster.error(err.statusText, `${err.status}`);
                break;

            case 504:
                this.toaster.error(err.message, err.error);
                break;

            default:
                this.toaster.error(err.error.message || err.message, err.error.error);
        }
    }

    private parse400Exception(errorResp: HttpErrorResponse): void {
        const error = errorResp.error;
        if (error.errors) {
            const body = `<ul>${error.errors.map((err: { defaultMessage: string }) => `<li>${err.defaultMessage}</li>`).join('')}</ul>`;
            this.toaster.warning(body, error.error, { enableHtml: true });
        } else {
            this.toaster.warning(error.message || errorResp.message, error.error);
        }
    }

    private parse500Exception(res: HttpErrorResponse): void {
        if (res.error) {
            if (res.error.error) {
                this.toaster.error(
                    `Unhandled server error occurred, please check server logs. Details: ${res.error.message}`,
                    res.error.error
                );
            } else {
                this.toaster.error(`${res.statusText}`, `Unhandled server error occurred`);
            }
        } else {
            this.toaster.error(res.message, 'Something went wrong');
        }
    }

    private handleBaseHttpServiceError(errorResp: HttpErrorResponse): void {
        const err = errorResp.error;
        this.toaster.error(`${err.status},  Message: ${err.error}`, `${err.message}`);
    }
}
