<span *ngIf="date" class="disable">
   <span *ngIf="now - parseDate(date) <= month">
       <fa-icon icon="clock" class="mr-1"></fa-icon>
       {{ date | timeAgo }}
   </span>
   <span *ngIf="now - parseDate(date) > month">
       <fa-icon icon="calendar" class="mr-1"></fa-icon>
       {{ date | date:'d MMMM y' }}
   </span>
</span>
