import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LayoutType, SORTING_FIELDS_DEPENDENT_BY_VERSION } from './assets-filter/assets-filter.component';
import { AssetFilter, AssetsService } from '../../../services/assets.service';
import { BehaviorSubject, concat, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Pager, Pagination, TranslationKeysOfPagination } from '@dagility-ui/kit';
import { AssetStatus } from '../asset.model';
import { ActivatedRoute, Router } from '@angular/router';
import { pickBy } from 'lodash';
import { Filter } from '../../../../assets/assets-page/assets-page-filters/assets-page-filters.component';
import { TranslationService } from '@app/core/services/translation.service';

const INITIAL_ASSETS_FILTER: AssetFilter = {
    searchTerm: '',
    page: 0,
    pageSize: 10,
    sort: 'name,asc',
    status: null,
};

const INITIAL_ASSETS_MANAGEMENT_FILTER: AssetFilter = {
    searchTerm: '',
    page: 0,
    pageSize: 10,
    sort: 'publishDate,desc',
};

@Component({
    selector: 'app-assets-list-wrapper',
    templateUrl: './assets-list-wrapper.component.html',
    styleUrls: ['./assets-list-wrapper.component.scss'],
})
export class AssetsListWrapperComponent implements OnInit, OnChanges {
    @Input() title: string;

    @Input() canAddAsset = false;

    @Input() showStatus = false;

    @Input() hasManagementActions = false;

    @Input() isManageAssets = true;

    @Input() isLoggedIn = false;

    @Input() selectedFilters: Filter[] = [];

    private _selectedCategoryFilter: Filter;
    @Input() set selectedCategoryFilter(value: Filter | undefined) {
        if (this.filter.categoryId === value?.id) {
            return;
        }
        this._selectedCategoryFilter = value;
        this.updateFilter({
            categoryId: value?.id,
        });
    }

    get filters(): Filter[] {
        return this.filter.categoryId && this._selectedCategoryFilter
            ? [this._selectedCategoryFilter, ...this.selectedFilters]
            : this.selectedFilters;
    }

    pagination: Pagination | null = null;

    layout: LayoutType = 'grid';

    filter: AssetFilter = this.isManageAssets ? INITIAL_ASSETS_MANAGEMENT_FILTER : INITIAL_ASSETS_FILTER;

    filterChanged$ = new BehaviorSubject<AssetFilter>(this.filter);

    filtersExpanded = false;

    assets$ = this.filterChanged$.pipe(
        tap(filter => {
            this.router
                .navigate([], {
                    relativeTo: this.route,
                    queryParams: pickBy(filter, val => val !== undefined && val !== ''),
                })
                .then();
        }),
        debounceTime(10),

        switchMap((filter: AssetFilter) => concat(of({ type: 'start' as const }), this.getAssets(filter)))
    );

    paginationTranslatedText: Partial<Record<TranslationKeysOfPagination, string>>;

    get assetStatus(): typeof AssetStatus {
        return AssetStatus;
    }

    private readonly destroy$ = new Subject();

    constructor(
        private assetsService: AssetsService,
        private router: Router,
        private route: ActivatedRoute,
        private translationService: TranslationService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.isManageAssets && changes.isManageAssets.currentValue !== changes.isManageAssets.previousValue) {
            this.updateFilter(this.isManageAssets ? INITIAL_ASSETS_MANAGEMENT_FILTER : INITIAL_ASSETS_FILTER);
        }
    }

    ngOnInit(): void {
        this.readQueryParams();
        this.translationService.translateObject('common.pagination').subscribe(
            pagination =>
                (this.paginationTranslatedText = {
                    SHOW: pagination.show,
                    ENTRIES: pagination.entries,
                    SHOWING: pagination.showing,
                    TO: pagination.to,
                    OF: pagination.of,
                    JUMP_TO: pagination.jumpTo,
                })
        );
    }

    private getAssets(filter: AssetFilter) {
        const substitutionFilter: AssetFilter = this.isManageAssets
            ? {
                  ...filter,
                  categoryId: null,
                  groupIds: null,
                  dropdownIds: null,
                  fieldValues: null,
                  sort: this.sortSubstitution(filter.sort),
              }
            : {
                  ...filter,
                  status: null,
                  sort: this.sortSubstitution(filter.sort),
              };
        return this.assetsService.get(substitutionFilter).pipe(
            tap(page => (this.pagination = Pagination.of(page))),
            map(page => page.content.map(asset => ({ ...asset, status: asset.status || AssetStatus.DRAFT })))
        );
    }

    private sortSubstitution(sortString: string) {
        const version: string = this.isManageAssets ? 'latestVersion' : 'currentVersion';
        SORTING_FIELDS_DEPENDENT_BY_VERSION.forEach(
            dependentField => (sortString = sortString.replace(dependentField, `${version}.${dependentField}`))
        );
        return sortString;
    }

    onPageChange({ currentPage, pageSize }: Pager) {
        this.updateFilter({
            page: currentPage - 1,
            pageSize,
        });
    }

    get isStatusFilterAll() {
        return (
            !this.filter.status ||
            (this.filter.status &&
                ![AssetStatus.AWAITING_FOR_APPROVAL, AssetStatus.AWAITING_LAUNCH, AssetStatus.REJECTED].includes(this.filter.status))
        );
    }

    changeStatusFilter(status?: AssetStatus) {
        this.updateFilter({ status });
    }

    updateFilter(newState: Partial<AssetFilter>) {
        this.filter = {
            ...this.filter,
            ...newState,
        };
        this.filterChanged$.next(this.filter);
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    private readQueryParams() {
        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            const filterFromParams: AssetFilter = { ...pickBy(params, val => val !== undefined && val !== '') } as AssetFilter;
            if (typeof filterFromParams.groupIds === 'string') {
                filterFromParams.groupIds = [filterFromParams.groupIds];
            }
            if (typeof filterFromParams.dropdownIds === 'string') {
                filterFromParams.dropdownIds = [filterFromParams.dropdownIds];
            }
            if (typeof filterFromParams.fieldValues === 'string') {
                filterFromParams.fieldValues = [filterFromParams.fieldValues];
            }
            if (!AssetStatus[filterFromParams.status]) {
                filterFromParams.status = null;
            }
            const restrictedFilter: AssetFilter = this.isManageAssets
                ? {
                      ...filterFromParams,
                      categoryId: null,
                      groupIds: null,
                      dropdownIds: null,
                      fieldValues: null,
                  }
                : {
                      ...filterFromParams,
                      status: null,
                  };
            this.updateFilter(restrictedFilter);
        });
    }

    removeFilter(filter: Filter) {
        if (filter.type === 'CATEGORY') {
            this.updateFilter({
                categoryId: null,
            });
        }
        if (filter.type === 'PUBLISHER') {
            this.updateFilter({
                groupIds: this.filter.groupIds.filter(id => id != filter.id),
            });
        }
        if (filter.type === 'TAXONOMY') {
            this.updateFilter({
                dropdownIds: this.filter.dropdownIds.filter(id => id != filter.id),
            });
        }
        if (filter.type === 'METADATA_BY_CATEGORY') {
            this.updateFilter({
                fieldValues: this.filter.fieldValues.filter(id => id != filter.id),
            });
        }
    }

    removeAllFilters() {
        this.updateFilter({
            categoryId: null,
            groupIds: [],
            dropdownIds: [],
            fieldValues: [],
        });
        this.filtersExpanded = false;
    }
}
