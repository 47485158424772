import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'app-header-navigation-dropdown',
    templateUrl: './header-navigation-dropdown.component.html',
    styleUrls: ['./header-navigation-dropdown.component.scss'],
})
export class HeaderNavigationDropdownComponent {
    @Input() title: string = null;
    @Input() activeParentDropdown: string = null;
    @Input() dropdownItems: DropdownItem[] = [];
    @Output() wentToLink = new EventEmitter<any>();

    activeDropdownId: string = null;
    activeDropdown: DropdownItem = null;

    constructor(private router: Router) {}

    showChildren(i: number, item: DropdownItem) {
        this.activeDropdownId = `${this.activeParentDropdown}-${i}`;
        this.activeDropdown = item;
    }

    goToLink(link: string, param: NavigationExtras, type: 'ROUTER' | 'HREF') {
        if (param && link) {
            this.wentToLink.emit();
            if (type === 'ROUTER') {
                this.router.navigate([link], param).then();
            } else {
                let href: string = `${link}?`;
                Object.keys(param.queryParams).forEach(k => (href += `${k}=${param.queryParams[k]}&`));
                window.open(href.slice(0, href.length - 2), '_blank');
            }
        }
    }
}

export interface DropdownItem {
    label: string;
    link: string;
    parameters: NavigationExtras;
    children: DropdownItem[];
}
