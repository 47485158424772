import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '@dagility-ui/kit';
import { environment } from '@app/env';
import { UserDto, UserRole, UsersFilter } from '@app/core/models/user.model';
import { UserBaseService } from './user-base.service';

@Injectable({
    providedIn: 'root',
})
export class UserService implements UserBaseService {
    readonly usersApi = environment.usersApiURL;
    readonly apiUrl = environment.serviceUrl;

    constructor(private http: HttpClient) {}

    getCurrentUser(): Observable<UserDto> {
        return this.http.get<UserDto>(`${environment.apiUrl}/current-user`);
    }

    getUsers(filter: UsersFilter): Observable<Page<UserDto>> {
        return this.http.get<Page<UserDto>>(this.usersApi, {
            params: {
                ...filter,
            },
        });
    }

    bulkRoleUpdate(userRoles: Array<{ userId: string; roles: UserRole[] }>) {
        return this.http.post(`${this.usersApi}/bulk-role-update`, userRoles);
    }

    getUser(userId: string): Observable<UserDto> {
        return this.http.get<UserDto>(`${this.usersApi}/${userId}`);
    }

    updateUser(user: UserDto): Observable<UserDto> {
        return this.http.put<UserDto>(this.usersApi, user);
    }

    uploadAvatar(userId: string, file: File): Observable<string> {
        const formData = new FormData();
        formData.append('image', file);
        return this.http.post<string>(`${this.usersApi}/${userId}/avatar`, formData);
    }

    deleteAvatar(userId: string): Observable<string> {
        return this.http.delete<string>(`${this.usersApi}/${userId}/avatar`);
    }
}
