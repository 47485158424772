import { KeycloakConfig } from 'keycloak-js';

const env = (<any>window).marketplace_env || {};
const apiUrl = env.services.url ? env.services.url + '/api' : 'https://dev-solutionhub.ustpace.com/api';
const service_url = `${apiUrl}/v1`;

export const environment = {
    production: true,
    apiUrl,
    serviceUrl: service_url,
    assetsApiURL: `${service_url}/assets`,
    reviewApiURL: `${service_url}/review`,
    usersApiURL: `${service_url}/users`,
    userGroupApiURL: `${service_url}/user-groups`,
    userProfileApiURL: `${service_url}/user-profile`,
    rolesApiURL: `${service_url}/roles`,
    categoriesApiURL: `${service_url}/categories`,

    adminApiURL: `${service_url}/admin/api`,
    metaDataApiURL: `${service_url}/metadata`,
    fileURL: `${service_url}/files`,
    stateMachineApiURL: `${service_url}/state-machine`,
    dpApiURL: `${service_url}/dp/api`,
    marketplaceApiUrl: `${service_url}/marketplace/api`,
    keycloakParams: {} as KeycloakConfig,
    helpSupportParams: Object.assign(
        {},
        {
            url: 'https://www.dagility.com',
            version: 3,
        }
    ),
};
