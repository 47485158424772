import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/env';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category } from '@app/shared/models/category.model';
import { TreelikeDropdownItem } from '@dagility-ui/kit';
import { AssetType } from '@app/shared/components/assets/asset.model';
import { settingsFuture, threeLayers } from '@app/shared/icons/solutionhub-icons';
import { cloneDeep } from 'lodash';
import { Id } from '@app/shared/services/assets.service';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    readonly categories$: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);
    readonly categoryItems$: BehaviorSubject<TreelikeDropdownItem[]> = new BehaviorSubject<TreelikeDropdownItem[]>([]);

    private readonly path = `${environment.categoriesApiURL}`;

    constructor(private http: HttpClient) {
        this.getCategories().subscribe(categories => {
            this.categories$.next(categories);
            this.categoryItems$.next(this.getCategoryItems(cloneDeep(categories)));
        });
    }

    getCategoryByName(name: string) {
        return this.searchCategory(this.categories$.value, name);
    }

    getCategoryById(id: Id) {
        return this.searchCategory(this.categories$.value, id);
    }

    getCategories(groupId: number = null): Observable<Category[]> {
        return this.http.get<Category[]>(this.path, { params: groupId ? { groupId } : null });
    }

    getCategoryItems(categories: Category[]): TreelikeDropdownItem[] {
        return categories.map(c => this.mapCategory(c));
    }

    private mapCategory(category: Category): TreelikeDropdownItem {
        if (!category.assetType) {
            return this.getTreelikeItem(category);
        } else {
            let icon;
            switch (category.assetType) {
                case AssetType.PRODUCT:
                    icon = settingsFuture;
                    break;
                case AssetType.SOLUTION:
                    icon = threeLayers;
                    break;
                default:
                    icon = null;
                    break;
            }
            return { ...this.getTreelikeItem(category), icon };
        }
    }

    private getTreelikeItem(category: Category): TreelikeDropdownItem {
        return {
            label: category.name,
            value: category.id,
            children: category.children.map(c => this.mapCategory(c)),
        };
    }

    private searchCategory(categories: Category[], value: number | string): Category {
        const searchTree = (array: Category[]): Category => {
            let result = null;
            for (let i = 0; result == null && i < array.length; i++) {
                result = searchNode(array[i]);
            }
            return result;
        };

        const searchNode = (item: Category): Category => {
            if (item.id === value || item.name === value) {
                return item;
            } else if (item.children?.length) {
                return searchTree(item.children);
            }
            return null;
        };

        return searchTree(categories);
    }
}
