import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { ContextHelpService } from '@app/shared/services/context-help.service';
import { AuthService } from '@app/auth';
import { SidenavService } from '@app/core/components/sidenav/sidenav.service';
import { StateMachineService } from '@app/shared/services/state-machine.service';
import { ThemeService } from '@app/core/services/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    private readonly formattedContextHelpData: any[] = [];

    readonly user$ = this.authService.user$;

    readonly sidenavItems$ = this.sidenavService.sidenavItems$;

    readonly assetEventsInit$ = this.stateMachineService.assetEventsInit$;

    constructor(
        private readonly router: Router,
        private readonly contextHelpService: ContextHelpService,
        private readonly themeService: ThemeService,
        private readonly authService: AuthService,
        private readonly sidenavService: SidenavService,
        private readonly stateMachineService: StateMachineService
    ) {}

    ngOnInit() {
        this.themeService.setLightTheme();

        this.handleLoadingForLazyLoadedModules();
    }

    ngAfterViewInit() {
        document.body.classList.add('app-initialization-completed');
    }

    processContextHelpData(helpData: any) {
        this.formattedContextHelpData.push(...this.contextHelpService.formatContextHelpData(helpData));
        if (
            helpData.hasOwnProperty('links') &&
            helpData.links.hasOwnProperty('next') &&
            helpData.links.next.hasOwnProperty('href') &&
            helpData.links.next.href !== ''
        ) {
            this.contextHelpService.getContextHelpPaginatedData(helpData.links.next.href).subscribe((responseData: any) => {
                this.processContextHelpData(responseData);
            });
        } else {
            sessionStorage.setItem('contextHelp', JSON.stringify(this.formattedContextHelpData));
        }
    }

    private handleLoadingForLazyLoadedModules() {
        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart || event instanceof GuardsCheckStart) {
                document.body.classList.add('lazy-loading');
            } else if (event instanceof RouteConfigLoadEnd || event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
                document.body.classList.remove('lazy-loading');
            }
        });
    }
}
