import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssetEventsService } from '@app/shared/services/asset-events.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { notEqualValue, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { AssetsService } from '@app/shared/services/assets.service';
import { AssetDto } from '@app/shared/components/assets/asset.model';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-upgrade-version-modal',
    templateUrl: './upgrade-version-modal.component.html',
})
export class UpgradeVersionModalComponent implements OnInit {
    readonly asset: AssetDto;

    loading = false;

    form: FormGroup;

    constructor(
        private assetEventService: AssetEventsService,
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private service: AssetsService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        const assetVersionCustomTextFn = () =>
            this.translocoService.translateObject('upgradeVersionModal.validationError') + ` - ${this.asset.assetVersion}`;

        this.form = this.fb.group({
            assetVersion: [this.asset.assetVersion, notEqualValue(this.asset.assetVersion, assetVersionCustomTextFn)],
        });

        setTimeout(validateFormAndDisplayErrors.bind(this, this.form));
    }

    upgrade() {
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        this.service
            .upgradeVersion(this.asset.id, this.form.getRawValue().assetVersion)
            .subscribe(newAssetVersionId => this.activeModal.close(newAssetVersionId));
    }
}
