import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { HelpSupportModule, PopupService } from '@dagility-ui/shared-components';
import { AccessDeniedComponent } from '@app/shared/components/access-denied/access-denied.component';
import { NotFoundComponent } from '@app/shared/components/not-found/not-found.component';
import { environment } from '@app/env';
import { AuthInterceptor } from '@app/core/http-interceptors/auth-interceptor';
import { HeaderModule } from '@app/core/components/header/header.module';

@NgModule({
    declarations: [AppComponent, HeaderComponent, AccessDeniedComponent, NotFoundComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        NgHttpLoaderModule.forRoot(),
        ToastrModule.forRoot(),
        AppRoutingModule,
        SharedModule.forRoot(),
        HelpSupportModule.forRoot(AuthInterceptor),
        HeaderModule,
    ],
    providers: [
        {
            provide: ToastrService,
            useClass: PopupService,
        },
        { provide: 'environment', useValue: environment },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
