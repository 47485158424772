<lib-card *transloco="let transloco; read: 'assets.listItem'"
          class="p-24px" [id]="'asset-item-' + item.id + '-' + item.assetVersionId"
          [style.min-width.px]="isGridView ? 420 : 650"
>
    <div class="d-flex asset-list-item justify-content-between">
        <app-asset-card class="d-flex text-truncate"
                        [class.asset-card-in-management]="isManageAssets"
                        [id]="'asset-card-' + item.id + '-' + item.assetVersionId"
                        [asset]="item"
                        [showStatus]="showStatus"
                        [isInList]="true"
                        [isManageAsset]="isManageAssets"
                        [isGridView]="isGridView"
        >
            <div class="mt-1 d-flex explore-link">
                <a [routerLink]="isManageAssets ? ('/manage-assets/' + item.id + '/version/' + item.assetVersionId) : ('/assets/' + item.id + '/version/' + item.assetVersionId)"
                   class="d-flex flex-row align-content-center font-12px"
                   [class.size-s]="isGridView"
                >
                    <div class="mr-7px">{{ transloco('explore') }}</div>
                    <fa-icon icon="facArrowRightRegular" class="fa" [ngClass]="isGridView ? 'font-12px' : 'font-16px'"
                    ></fa-icon>
                </a>
            </div>
        </app-asset-card>
        <div class="asset-list-item--actions d-flex flex-row align-items-center"
             [id]="'asset-actions-' + item.id + '-' + item.assetVersionId"
             [ngClass]="isGridView ? 'grid-view-buttons' : 'list-view-buttons'"
             [class.hide-button-text]="hideButtonText"
        >
            <div *ngIf="hasManagementActions" class="d-flex flex-row align-items-center">
                <ng-container [ngSwitch]="item.status">
                    <ng-container
                        *ngSwitchCase="item.status === 'DRAFT' || item.status === 'REJECTED' ? item.status : ''"
                    >
                        <button
                            *ngIf="(stateMachineService.canSaveDraft || stateMachineService.canSubmit) && item.createdBy == currentUser.id"
                            class="btn btn-success action-button"
                            [class.size-s]="isGridView"
                            [routerLink]="item.id + '/' + item.assetVersionId"
                            [ngbTooltip]="hideButtonText ? transloco('editBtn') : null"
                            container="body"
                        >
                            <fa-icon icon="facEdit" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('editBtn') }}</div>
                        </button>
                    </ng-container>

                    <ng-container *ngSwitchCase="'AWAITING_FOR_APPROVAL'">
                        <button *ngIf="stateMachineService.canApprove"
                                class="btn btn-success action-button"
                                [class.size-s]="isGridView"
                                (click)="handleEvent(events.ADMIN_APPROVE)"
                                [ngbTooltip]="hideButtonText ? transloco('approveBtn') : null"
                                container="body"
                        >
                            <fa-icon icon="simpleCheck" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('approveBtn') }}</div>
                        </button>
                        <button *ngIf="stateMachineService.canReject"
                                class="btn btn-danger action-button"
                                [class.size-s]="isGridView"
                                (click)="handleReject()"
                                [ngbTooltip]="hideButtonText ? transloco('rejectBtn') : null"
                                container="body"
                        >
                            <fa-icon icon="facDelete" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('rejectBtn') }}</div>
                        </button>
                    </ng-container>

                    <ng-container *ngSwitchCase="'AWAITING_LAUNCH'">
                        <button *ngIf="stateMachineService.canLaunch"
                                class="btn btn-success action-button"
                                [class.size-s]="isGridView"
                                (click)="handleEvent(events.LAUNCH)"
                                [ngbTooltip]="hideButtonText ? transloco('launchBtn') : null"
                                container="body"
                        >
                            <fa-icon icon="resumePlay" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('launchBtn') }}</div>
                        </button>
                        <button *ngIf="stateMachineService.canCancel"
                                class="btn btn-danger action-button"
                                [class.size-s]="isGridView"
                                (click)="handleEvent(events.CANCEL)"
                                [ngbTooltip]="hideButtonText ? transloco('cancelBtn') : null"
                                container="body"
                        >
                            <fa-icon icon="facStop" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('cancelBtn') }}</div>
                        </button>
                    </ng-container>

                    <ng-container *ngSwitchCase="'LAUNCHED'">
                        <button *ngIf="stateMachineService.canUpgradeVersion && item.createdBy == currentUser.id"
                                class="btn btn-warning action-button"
                                [class.size-s]="isGridView"
                                (click)="handleUpgradeVersion()"
                                [ngbTooltip]="hideButtonText ? transloco('upgradeVersion') : null"
                                container="body"
                        >
                            <fa-icon icon="cog" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('upgradeVersion') }}</div>
                        </button>
                    </ng-container>
                </ng-container>
            </div>
            <div *ngIf="!readOnly" class="d-flex flex-row align-items-center">
                <button *ngIf="isLoggedIn && (currentUser.groupId === item.groupId || isAdmin) && item.downloadable?.length"
                        class="btn btn-warning asset-download-btn action-button"
                        [class.size-s]="isGridView"
                        (click)="handleDownload(item)"
                        [ngbTooltip]="hideButtonText ? transloco('download') : null"
                        container="body"
                >
                    <fa-icon icon="facDownload" class="fa button-icon"></fa-icon>
                    <div class="button-text">{{ transloco('download') }}</div>
                </button>
                <a *ngIf="isLoggedIn && item.repoUrl"
                   class="btn btn-primary asset-github-link action-button"
                   [class.size-s]="isGridView"
                   target="_blank"
                   [href]="item.repoUrl | redirectLink"
                   [ngbTooltip]="hideButtonText ? transloco('link') : null"
                   container="body"
                >
                    <fa-icon icon="facExternalLink" class="fa button-icon"></fa-icon>
                    <div class="button-text">{{ transloco('link') }}</div>
                </a>
                <a *ngIf="isGridView"
                   [routerLink]="isManageAssets ? ('/manage-assets/' + item.id + '/version/' + item.assetVersionId) : ('/assets/' + item.id + '/version/' + item.assetVersionId)"
                   class="asset-link btn btn-secondary size-s action-button"
                   [class.size-s]="isGridView"
                   [ngbTooltip]="hideButtonText ? transloco('explore') : null"
                   container="body"
                >
                    <div class="button-text mr-7px">{{ transloco('explore') }}</div>
                    <fa-icon icon="facArrowRightRegular" class="fa button-icon" style="margin: 0 !important;"></fa-icon>
                </a>
            </div>
        </div>
    </div>
</lib-card>
