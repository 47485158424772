export type UserRole = 'ROLE_CONSUMER' | 'ROLE_PRODUCER' | 'ROLE_USER_GROUP_ADMIN' | 'ROLE_ADMIN';

export interface UserDto {
    id: string;
    oauthId: string;
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    name: string;
    createdAt: string;
    lastModifiedAt: string;
    avatarExists: boolean;
    roles: UserRole[];
    groupId: number;
    groupName: string;
}

export interface RoleDto {
    id: number;
    key: UserRole;
    name: string;
}

export const ROLE_ADMIN: UserRole = 'ROLE_ADMIN';
export const ROLE_USER_GROUP_ADMIN: UserRole = 'ROLE_USER_GROUP_ADMIN';
export const ROLE_PRODUCER: UserRole = 'ROLE_PRODUCER';
export const ROLE_CONSUMER: UserRole = 'ROLE_CONSUMER';

export const userRoleMap: Map<UserRole, string> = new Map([
    [ROLE_CONSUMER, 'Consumer'],
    [ROLE_PRODUCER, 'Producer'],
    [ROLE_USER_GROUP_ADMIN, 'Group Admin'],
    [ROLE_ADMIN, 'Admin'],
]);

export function mostValuableUserRole(userRoles: UserRole[]): UserRole {
    if (!userRoles) {
        return null;
    }
    if (userRoles.includes(ROLE_ADMIN)) {
        return ROLE_ADMIN;
    }
    if (userRoles.includes(ROLE_USER_GROUP_ADMIN)) {
        return ROLE_USER_GROUP_ADMIN;
    }
    if (userRoles.includes(ROLE_PRODUCER)) {
        return ROLE_PRODUCER;
    }
    if (userRoles.includes(ROLE_CONSUMER) || userRoles.length === 0) {
        return ROLE_CONSUMER;
    }
    return null;
}

export function isAdminRoleMostValuable(userRoles: UserRole[]): boolean {
    return mostValuableUserRole(userRoles) === ROLE_ADMIN;
}

export function isGroupAdminRoleMostValuable(userRoles: UserRole[]): boolean {
    return mostValuableUserRole(userRoles) === ROLE_USER_GROUP_ADMIN;
}

export function isProducerRoleMostValuable(userRoles: UserRole[]): boolean {
    return mostValuableUserRole(userRoles) === ROLE_PRODUCER;
}

export interface UsersFilter {
    searchQuery: string;
    page: number;
    size: number;
    roles: UserRole[];
    userGroupId?: number;
    sort: string[];
}
