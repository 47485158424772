<div class="d-flex flex-wrap mt-4px" *transloco="let t; read: 'common.tags'">
    <lib-tag *ngFor="let tag of tags.slice(0, expanded ? tags.length : 6)"
             [tag]="tag"
             class="mr-8px mb-8px"
    ></lib-tag>
    <button *ngIf="tags.length > 6 && !expanded"
            (click)="expanded = true"
            class="btn btn-link size-s"
            style="margin-top: -4px;"
    >
        +{{ tags.length - 6 }} {{ t('moreTags') }}
    </button>
</div>
