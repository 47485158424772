import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@app/auth';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({ selector: '[isAuthorized]' })
export class IsAuthorizedDirective implements OnInit, OnDestroy {
    private hasView = false;

    private destroyed$ = new Subject();

    constructor(private templateRef: TemplateRef<any>, private vcRef: ViewContainerRef, private authService: AuthService) {}

    ngOnInit() {
        this.authService.user$.pipe(takeUntil(this.destroyed$)).subscribe(user => {
            const isAuthorized = !!user;

            if (isAuthorized && !this.hasView) {
                this.vcRef.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!isAuthorized && this.hasView) {
                this.vcRef.clear();
                this.hasView = false;
            }
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }
}
