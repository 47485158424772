import { Pipe, PipeTransform } from '@angular/core';
import { AssetDto } from '@app/shared/components/assets/asset.model';
import { environment } from '@app/env';

@Pipe({
    name: 'assetLogoUrl',
})
export class AssetLogoUrlPipe implements PipeTransform {
    transform(asset: AssetDto) {
        if (!asset.logoExists) {
            return '';
        }
        return `${environment.assetsApiURL}/${asset.id}/versions/${asset.assetVersionId}/logo${
            asset.lastModifiedAt ? '?t=' + Date.parse(asset.lastModifiedAt) : ''
        }`;
    }
}
