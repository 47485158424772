<lib-card *transloco="let t; read: 'assets.filter'" [style.min-width.px]="650">
    <div class="d-flex">
        <lib-search class="flex-1"
                    [placeholder]="t('search')"
                    [value]="filter.searchTerm"
                    (searchText)="search($event)"
        ></lib-search>
        <lib-split-button
            class="mx-3"
            [buttonText]="t('sortingBy') + sortingButtonText"
            [items]="sortingItems"
            [isPrimary]="false"
            [container]="'body'"
            (itemClick)="changeSorting($event)"
        >
        </lib-split-button>
        <div class="button-toggle-group">
            <input
                id="horizontal-selector"
                type="radio"
                name="selector"
                value="list"
                [ngModel]="layout"
                (ngModelChange)="layoutChange.emit($event)"
            />
            <label
                for="horizontal-selector"
                class="button-toggle"
                [ngbTooltip]="t('listViewTooltip')"
                placement="bottom"
                container="body"
                [openDelay]="500"
            >
                <fa-icon icon="grid2Horizontal"></fa-icon>
            </label>
            <input
                id="grid-selector"
                type="radio"
                name="selector"
                value="grid"
                [ngModel]="layout"
                (ngModelChange)="layoutChange.emit($event)"
            />
            <label
                for="grid-selector"
                class="button-toggle"
                [ngbTooltip]="t('gridViewTooltip')"
                placement="bottom"
                container="body"
                [openDelay]="500"
            >
                <fa-icon icon="grid"></fa-icon>
            </label>
        </div>
    </div>

    <ng-content></ng-content>
</lib-card>
