import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@app/env';
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

function loadAuthConfig(): Promise<void> {
    return fetch(`${environment.apiUrl}/oauth2-config`)
        .then(res => res.json())
        .then(config => {
            const env = (<any>window).marketplace_env || {};
            environment.keycloakParams = Object.assign({}, config, env.keycloak);
        });
}

function bootstrap() {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(e => {
            console.error(e);
            showError();
        });
}

(async () => {
    await loadAuthConfig();
    bootstrap();
})();

function showError() {
    // hide loader
    const loader = document.getElementById('loader');
    loader.parentNode.removeChild(loader);

    document.body.insertAdjacentHTML(
        'beforeend',
        `<div class="bootstrap-error"><p>Something went wrong</p><div class="btn btn-primary size-s">Try again</div></div>`
    );
    const errorCard = document.body.lastChild as HTMLDivElement;
    errorCard.querySelector<HTMLDivElement>('.btn').onclick = () => location.reload();
}
