import { Injectable } from '@angular/core';
import { ReviewModal, ReviewModalComponent } from '@app/shared/components/assets/asset-card/review-modal/review-modal.component';
import { ReviewDto } from '@app/shared/models/review.model';
import { ModalService } from '@dagility-ui/kit';
import { AssetsService } from '@app/shared/services/assets.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from '@app/core/services/translation.service';
import { AssetDto } from '@app/shared/components/assets/asset.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReviewModalService {
    reviewLeft$ = new BehaviorSubject<ReviewDto>(null);

    constructor(
        private modalService: ModalService,
        private assetsService: AssetsService,
        private toastrService: ToastrService,
        private translationService: TranslationService
    ) {}

    writeReview(asset: AssetDto) {
        const modalRef = this.modalService.open(ReviewModalComponent, {
            centered: true,
            backdrop: 'static',
            size: 'lg',
        });

        modalRef.result
            .then((reviewModal: ReviewModal) => {
                const review: ReviewDto = {
                    assetId: asset.id,
                    assetVersionId: asset.assetVersionId,
                    ...reviewModal,
                };

                this.assetsService.createReview(review.assetId, review.assetVersionId, review).subscribe(
                    () => {},
                    () => {},
                    () => {
                        this.reviewLeft$.next(review);
                        this.translationService
                            .translateObject('common.toastr')
                            .subscribe(rejectToastr =>
                                this.toastrService.success(rejectToastr.review.reviewLeft, rejectToastr.successTitle)
                            );
                    }
                );
            })
            .catch(() => {});
    }
}
