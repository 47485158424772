import { HttpClient } from '@angular/common/http';
import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@app/env';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/translations/${lang}.json`);
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [{ id: 'en', label: 'English' }],
                reRenderOnLangChange: true,
                prodMode: environment.production,
                fallbackLang: 'en',
                defaultLang: 'en',
                missingHandler: {
                    useFallbackTranslation: false,
                },
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
    imports: [
        TranslocoMessageFormatModule.forRoot(),
        TranslocoLocaleModule.forRoot({
            langToLocaleMapping: {
                en: 'en-US',
            },
        }),
    ],
})
export class TranslocoRootModule {}
